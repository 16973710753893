.main{
    margin:0;
    padding:0;
    background-color: orange;
    display:flex;
    justify-content: center;
    align-items: center;
    min-height:100vh;
}

.clock h1{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size:4em;
    font-weight:500;
    color:#16CC86;
}
.clock,.screen{
    display:flex;
    justify-content: center;
    align-items: center;
}
.clock{
    width:25em;
    height:25em;
    background-color: #111;
    border-radius: 20px;
}
.screen{
    width:25em;
    height:10em;
    border:4px solid #000;
    border-radius: 15px;
    background: linear-gradient(
        -45deg,#1f272b 0%,
        #1f272b 50%,
        #1b1f21 50%,
        #1b1f21 100%
    );
}