.form__content__register h1{
    text-align: center;

    color:orange;
}

.form__content__register{
   
    padding:0.5em;
    
}
.register__submit{
     margin:4em;
     width:500px;
     height:450px;
}
.form__content__register span{
    text-align:center;
    color:red;
    
}

input{
    width:100%;
    padding:10px;
}
label{
    padding:10px;
}

.register__submit button{
    background-color:red;
    width:500px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
.register__submit button:hover{
    background-color:orangered;
}
.register__submit a{
    text-decoration: none;
    color:orange;
}
.register__submit span{
    color:#111;
}
@media(max-width:1029px){
    .register__submit{
    
        margin:4em;
       width:500px;
       height:450px;
       margin-left:4rem;
   }
   .form-register{
    position: absolute;
    top:20%;
    left:30%;
    margin-left:-2rem;
   }
}
@media(max-width:810px){
    .register__submit{
      
        margin:4em;
       width:500px;
       height:450px;
       margin-left:4rem;
       
   }
   .form-register{
  
 
    margin-left:-8rem;
   }
}
@media(max-width:645px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:450px;
       height:550px;
    margin-left:-6rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:390px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
}
@media(max-width:550px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:450px;
       height:550px;
    margin-left:-8rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:390px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
}
@media(max-width:480px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:400px;
       height:500px;
    margin-left:-7rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:340px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
}
@media(max-width:430px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:380px;
       height:520px;
    margin-left:-7rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:330px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
}
@media(max-width:400px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:350px;
       height:520px;
    margin-left:-6rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:320px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
 input{
    width:100px;
}
}

@media(max-width:380px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:300px;
       height:530px;
    margin-left:-4rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:260px !important;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}

}
@media(max-width:360px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:300px;
       height:530px;
    margin-left:-5rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:260px !important;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}

}
@media(max-width:340px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:300px;
       height:530px;
    margin-left:-5rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:260px !important;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}

}
@media(max-width:332px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:280px;
       height:530px;
    margin-left:-5rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:220px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}

}