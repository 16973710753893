
.form-container{
   

    width:350px;
    height:400px;
    background-color: #ffff;
}
.form-general h1{
    text-align: center;
    font-size: 35px;
    color:orange;
 
    padding:15px;
  
}
input{
    width:100%;
    padding:10px;
}
label{
    padding:10px;
}
.form-container button{
    background-color:red;
    width:370px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
.form-container button:hover{
    background-color:orangered;
}