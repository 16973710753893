.position-center img{
  width:60px;
}
.position-center a{
    text-decoration: none;
    cursor: pointer;
}
.whatsApp{
    position: absolute;
    top:90%;
    left:80%
}
@media(max-width:980px){
  .whatsApp{
    position: absolute;
    top:80%;
    left:80%
}
}
@media(max-width:860px){
  .whatsApp{
    position: absolute;
    top:70%;
    left:80%
}
}
@media(max-width:840px){
  .whatsApp{
    position: absolute;
    top:60%;
    left:80%
}
}