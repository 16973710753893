.link_to_days{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.link_to_days a{
    text-decoration: none;
    color:red;
}
.link_to_days p{
    font-size:18px;
}