.overall-content{
    padding:0.5rem;
}
.flex-container{
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.name{
    color: orangered;
}
.overall-content button{
    color:white;
    padding:5px;
    background-color: orange;
}