
.social__icons{
    background-color: #111;
    color:white;
    padding:10px;
    padding:1rem;
}
.icons{
    display:flex;
    justify-content: space-around;
    align-items:center;
}
.icons a{
    text-decoration: none;
    cursor: pointer;
}