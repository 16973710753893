.payment_cards{
    padding:2rem;
   
}
.payment_cards_three{
    display:flex;
    justify-content: center;
    align-items: center;
    gap:5px;
}
.payment_cards_three img{
    width:180px;
    height:180px;
    border-radius:50%;
}
.igsce{
    width:400px;
    height:500px;
    background-color: white;
    padding:10px;
    text-align: center;
    border-radius: 10px;
    filter: drop-shadow(.75em .75em .75em);
}
.bgsce{
    width:400px;
    height:600px;
     background-color: white;
     text-align: center;
     border-radius: 10px;
     filter: drop-shadow(.75em .75em .75em);
  
}
.cambridge{
    width:400px;
    height:500px;
    background-color:white;
    padding:10px;
    text-align: center;
    border-radius: 10px;
    filter: drop-shadow(.75em .75em .75em);
}
.payment_cards_three p{
    padding:5px;
    color:black;
}
.payment_cards_three h2{
    
    color:orange;
}
@media(max-width:1029px){
    .payment_cards_three{
    
      flex-wrap: wrap;
    }
    .payment_cards_three img{
        width:100px;
        height:100px;
        border-radius:50%;
    }
    .igsce{
        width:400px;
        height:500px;
        background-color: white;
        padding:10px;
        text-align: center;
        border-radius: 10px;
        filter: drop-shadow(.75em .75em .75em);
    }
    .bgsce{
        width:400px;
        height:600px;
         background-color: white;
         text-align: center;
         border-radius: 10px;
         filter: drop-shadow(.75em .75em .75em);
      
    }
    .cambridge{
        width:400px;
        height:500px;
        background-color:white;
        padding:10px;
        text-align: center;
        border-radius: 10px;
        filter: drop-shadow(.75em .75em .75em);
    }
    .payment_cards_three p{
        padding:5px;
        color:black;
    }
    .payment_cards_three h2{
        
        color:orange;
    } 
}
@media(max-width:469px){
   
    .payment_cards_three{
        flex-direction: column;
        flex-wrap: wrap;
      }
      .payment_cards_three img{
          width:100px;
          height:100px;
          border-radius:50%;
      }
      .igsce{
          width:350px;
          height:470px;
          background-color: white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          filter: drop-shadow(.75em .75em .75em);
      }
      .bgsce{
          width:350px;
          height:600px;
           background-color: white;
           text-align: center;
           border-radius: 10px;
           filter: drop-shadow(.75em .75em .75em);
        
      }
      .cambridge{
          width:350px;
          height:430px;
          background-color:white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          filter: drop-shadow(.75em .75em .75em);
      }
      .payment_cards_three p{
          padding:5px;
          color:black;
      }
      .payment_cards_three h2{
          
          color:orange;
      } 
}
@media(max-width:415px){
   
    .payment_cards_three{
        flex-direction: column;
        flex-wrap: wrap;
      }
      .payment_cards_three img{
          width:100px;
          height:100px;
          border-radius:50%;
      }
      .igsce{
          width:300px;
          height:440px;
          background-color: white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          filter: drop-shadow(.75em .75em .75em);
      }
      .bgsce{
          width:300px;
          height:500px;
           background-color: white;
           text-align: center;
           border-radius: 10px;
           filter: drop-shadow(.75em .75em .75em);
        
      }
      .cambridge{
          width:300px;
          height:440px;
          background-color:white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          filter: drop-shadow(.75em .75em .75em);
      }
      .payment_cards_three p{
          padding:5px;
          color:black;
      }
      .payment_cards_three h2{
          font-size:24px;
          color:orange;
      } 
}

@media(max-width:356px){
   
    .payment_cards_three{
        flex-direction: column;
        flex-wrap: wrap;
      }
      .payment_cards_three img{
          width:100px;
          height:100px;
          border-radius:50%;
      }
      .igsce{
          width:280px;
          height:460px;
          background-color: white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          filter: drop-shadow(.75em .75em .75em);
      }
      .bgsce{
          width:280px;
          height:500px;
           background-color: white;
           text-align: center;
           border-radius: 10px;
           filter: drop-shadow(.75em .75em .75em);
        
      }
      .cambridge{
          width:280px;
          height:460px;
          background-color:white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          filter: drop-shadow(.75em .75em .75em);
      }
      .payment_cards_three p{
          padding:5px;
          color:black;
      }
      .payment_cards_three h2{
          font-size:24px;
          color:orange;
      } 
}

@media(max-width:336px){
    .payment_cards{
        margin-left:-15px;
       
    }
    .payment_cards_three{
        flex-direction: column;
        flex-wrap: wrap;
      }
      .payment_cards_three img{
          width:100px;
          height:100px;
          border-radius:50%;
      }
      .igsce{
          width:280px;
          height:460px;
          background-color: white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          filter: drop-shadow(.75em .75em .75em);
      }
      .bgsce{
          width:280px;
          height:500px;
           background-color: white;
           text-align: center;
           border-radius: 10px;
           filter: drop-shadow(.75em .75em .75em);
        
      }
      .cambridge{
          width:280px;
          height:460px;
          background-color:white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          filter: drop-shadow(.75em .75em .75em);
      }
      .payment_cards_three p{
          padding:5px;
          color:black;
      }
      .payment_cards_three h2{
          font-size:24px;
          color:orange;
      } 
}