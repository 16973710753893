.brands-main{
    padding: 1rem;

}
.brands-main h1{
    text-align: center;
    font-size:45px;
    color:orange;
}
.brand-images{
    display: flex;
  justify-content: space-evenly;
    align-items: center;
  
}
.brand-images img{
    width:200px;
    height: 200px;
    border-radius: 50%;
}
@media(max-width:800px){
    .brand-images{
        flex-direction: column;
    }
    .brand-images img{
        width:400px;
        height: 400px;
        border-radius: 50%;
    }
}
@media(max-width:500px){
    .brands-main h1{
        text-align: center;
        font-size:35px;
        color:orange;
    }
    .brand-images{
        flex-direction: column;
    }
    .brand-images img{
        width:300px;
        height: 300px;
        border-radius: 50%;
    }
}
@media(max-width:330px){
    .brands-main h1{
        text-align: center;
        font-size:30px;
        color:orange;
    }
    .brand-images{
        flex-direction: column;
    }
    .brand-images img{
        width:250px;
        height: 250px;
        border-radius: 50%;
    }
}