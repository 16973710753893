.video{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
     z-index: 999;
}
.video video{
    width:100%;
    height:100%;
    pointer-events: none;
}
.video-overlay{
    position: absolute;
    top:70%;
    left:40%;
    transform: translate(-50%,-50%);
}
.video-overlay h1{
    color:white;
}
.video-overlay .lesson{
    width:150px;
    height:45px;
    outline:0;
    border-radius: 20px;
    border-style: none;
    cursor: pointer;
    color:white;
    background-color: red;
   margin:6px;
}
.video-overlay .lesson:hover{
    background-color: orange;
}
.video-overlay .register{
    width:190px;
    height:35px;
    outline:0;
    border-style: none;
    cursor: pointer;
    border-radius: 12px;
    color:white;
    background-color: blue;
}
.video-overlay a{
    text-decoration: none;
}
.video-overlay .register:hover{
    background-color: teal;
}

.video-overlay .bold{
    font-size:45px;
    font-weight: bold;
}
.video-overlay .big{
    font-size:26px;
    font-weight: bold;
}
@media(max-width:1029px){
    .video-overlay{
        margin-left:3rem;
    }
}
@media(max-width:672px){
    .video-overlay{
        position: absolute;
        top:45%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:35px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:20px;
        font-weight: bold;
    }
}
@media(max-width:656px){
    .video video{
        width:150%;
        
        pointer-events: none;
    }
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:35px;
        font-weight: bold;
        width:100%;
    }
    .video-overlay .big{
        font-size:20px;
        font-weight: bold;
    }
}
@media(max-width:619px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
}
@media(max-width:586px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
}
@media(max-width:557px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:33px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:21px;
        font-weight: bold;
    }
}
@media(max-width:535px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:33px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:21px;
        font-weight: bold;
    }
}
@media(max-width:518px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:29px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:18px;
        font-weight: bold;
    }
    .video-overlay .lesson{
        width:130px;
        height:35px;
        outline:0;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:120px;
        height:25px;
        outline:0;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
    }
}
@media(max-width:459px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:25px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:18px;
        font-weight: bold;
    }
    .video-overlay .lesson{
        width:120px;
        height:35px;
        outline:0;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:100px;
        height:35px;
        outline:0;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
    }
}
@media(max-width:450px){
    .video video{
        width:180%;
        
        pointer-events: none;
    }
}
@media(max-width:416px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:25px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:19px;
        font-weight: bold;
    }
    .video-overlay .lesson{
        width:200px;
        height:45px;
        outline:0;
        font-size:18px;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:200px;
        height:35px;
        outline:0;
        font-size:18px;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
        margin-left:5px;
    }
}
@media(max-width:406px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:25px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:19px;
        font-weight: bold;
    }
    .video-overlay .lesson{
        width:200px;
        height:45px;
        outline:0;
        font-size:18px;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:200px;
        height:35px;
        outline:0;
        font-size:18px;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
        margin-left:5px;
    }
}

@media(max-width:400px){
    .video-overlay{
        position: absolute;
        top:55%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:23px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:17px;
        font-weight: bold;
    }
    .video-overlay .lesson{
        width:200px;
        height:45px;
        outline:0;
        font-size:18px;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:200px;
        height:35px;
        outline:0;
        font-size:18px;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
        margin-left:5px;
    }
}

@media(max-width:384px){
    .video-overlay{
        position: absolute;
        top:55%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:23px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:18px;
        font-weight: bold;
     
    }
    .video-overlay .lesson{
        width:200px;
        height:45px;
        outline:0;
        font-size:16px;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:200px;
        height:35px;
        outline:0;
        font-size:16px;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
    }
}


@media(max-width:383px){
    .video-overlay{
        position: absolute;
        top:55%;
        left:30%;
        transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:23px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:18px;
        font-weight: bold;
        
    }
    .video-overlay .lesson{
        width:200px;
        height:45px;
        outline:0;
        font-size:16px;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:200px;
        height:35px;
        outline:0;
        font-size:16px;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
    }
}
@media(max-width:375px){
    .video video{
        width:200%;
        
        pointer-events: none;
    }
}
@media(max-width:340px){
    .video video{
        width:230%;
        
        pointer-events: none;
    }
}