
.layout{
    display:flex;
}
main {
    width: 100%;
 padding: 20px;
color: #ccc;
}
.side-bar{
    position: absolute;
    top: 19%;
    left: 0;
    width: 230px;
    height: 630px;
    flex-shrink: 0;
    background-color: #111;
    overflow: auto;
    color: white;
    transition: all 0.5s; 
    overflow: hidden;
}
.logo-top img{
    width:50px;
    height:50px;
    border-radius: 50%;
}
.top-section{
    display: flex;
    align-items: center;
    padding: 10px 15px;
    transition: all 0.5s;
  background-color: blue;
  height: 70px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 70;
    cursor: pointer;
    color: white;
    transition: all 0.3s;
}