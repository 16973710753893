.side-bar-rounded-all{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.inbox-message{
    color:orange;
}
.inbox-message a{
    text-decoration: none;
}
.school-reports a{
    text-decoration: none;
}