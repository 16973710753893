.pay__all{
    margin-left:2rem;
}
.pay_content{
    display:flex;
    justify-content: space-around;
    align-items: center;
    padding:2em;
}
.pay__all h1{
    text-align: center;
    font-size:30px;
    text-transform: capitalize;
    color:orange;
}

.pay_content p{
    width:100%;
   
}
@media(max-width:996px){
    .terms{
        margin:12px;
    }
}
 @media(max-width:730px){
    .pay__all{
        margin-left:2rem;
    }
    .pay_content{
       flex-direction: column;
        padding:1em;
    }
    .pay__all h1{
        text-align: center;
        font-size:20px;
        text-transform: capitalize;
        color:orange;
    }
    
    .pay_content p{
        width:100%;
       
    }
 }
@media(max-width:430px){
    .pay__all h1{
        text-align: center;
        font-size:20px;
        text-transform: capitalize;
        color:orange;
    } 
}