
.overall-side{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    background-color: orange;
}
.overall-side img{
    width:600px;
}
.content a{
    width:130px;
    height:30px;
    background-color: red;
    outline: none;
    margin-top: 10px;
    border-style: none;
    padding:5px;
    border-style: none;
    border:2px solid white;
}
.overall-side p{
    width:100%;
   
}

.side a{
    text-decoration: none;
    cursor: pointer;
}
.side span{
    
    color:blue;
}
.side h4{
    color:orangered;
}
.side h1{
    color:orange;
}
@media(max-width:1029px){
    .overall-side{
        flex-direction: column;
        flex-wrap: wrap;
        padding:0.5rem;
    }
    .overall-side p{
        width:80%;
     
    }
    .overall-side img{
        width:650px;
    }
    .content{
        margin-right:3rem;
    }
}
@media(max-width:670px){
    .overall-side{
        flex-direction: column;
    }
   
    .overall-side img{
        width:650px;
    }
    .content{
        margin-right:100px;
    }
    .content a{
        margin:15px;
    }
    .content p{
        width:100%;
       
        
    }
}

@media(max-width:650px){
    .overall-side{
        flex-direction: column;
    }
   
    .overall-side img{
        width:600px;
    }
    .content{
        margin-right:150px;
    }
    .content a{
        margin:15px;
    }
    .content p{
        width:100%;
       
        
    }
}

@media(max-width:570px){
    .overall-side{
        flex-direction: column;
    }
   
    .overall-side img{
        width:550px;
    }
    .content{
        margin-right:150px;
    }
    .content a{
        margin:15px;
    }
    .content p{
        width:120%;
       
        
    }
    .content h1{
        font-size:30px;
    }
}


@media(max-width:530px){
    .overall-side{
        flex-direction: column;
    }
   
    .overall-side img{
        width:500px;
    }
    .content{
        margin-right:190px;
    }
    .content a{
        margin:15px;
    }
    .content p{
        width:160%;
       
        
    }
    .content h1{
        font-size:22px;
    }
}


@media(max-width:460px){
    .overall-side{
        flex-direction: column;
        margin-right:270px;
        width:100%;
    }
   
    .overall-side img{
        width:450px;
    }
  
    .content a{
        margin:15px;
    }
    .content p{
        width:160%;
       
        
    }
    .content h1{
        font-size:22px;
    }
}
@media(max-width:400px){
    .overall-side{
        flex-direction: column;
        
       
    }
   .content{
    margin-left:2.5rem;
   }
  
    .overall-side img{
        width:380px;
     
    }
  
  
    .content p{
        width:160%;
       
        
    }
    .content h1{
        font-size:22px;
    }
}

@media(max-width:350px){
    .overall-side{
        flex-direction: column;
        
       
    }
   .content{
    margin-left:1.5rem;
   }
  
    .overall-side img{
        width:350px;
     
    }
  
  
    .content p{
        width:160%;
       
        
    }
    .content h1{
        font-size:22px;
    }
}

@media(max-width:330px){
    .overall-side{
        flex-direction: column;
        
       
    }
   .content{
    margin-left:1rem;
   }
  
    .overall-side img{
        width:370px;
       padding-right:80px;
    }
  
  
    .content p{
        width:160%;
       
        
    }
    .content h1{
        font-size:22px;
    }
}