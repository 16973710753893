.calendar-plus-table{
   
    background-color: orangered;
    color:white;
}
.time-table{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px;
}
.time-table h3{
    color:white;
}
.time-table a{
    text-decoration: none;
    color:white;
}
.time-table input{
    padding:10px;
    width:200px;
    margin:5px;
    background-color:white;
    cursor: pointer;
}
.list-of-forms {
    font-size:20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.list-of-forms {
    font-size:20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.sub-list-styles p a{
    display:none;

}
.sub-list-styles a{
    text-decoration: none;
    color:white;
    font-size:20px;
}
.list-of-forms :hover .sub-list-styles {
    display:block;
    background-color: red;
    
}
.classes-and-days{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.space{
    margin-right: 20px;
    
}
.drop{
    display:none;
}
.space :hover .drop{
 display: block;
 cursor: pointer;
}
.classes-and-days button{
    width:200px;
    height:40px;
    background-color: white;
    color:red;
    cursor: pointer;
    border: none;
    outline:none;
    padding:10px;
}
@media(max-width:766px){
    .time-table{
        flex-direction: column;
    }
}