.competent_staff{
    padding:1rem;
}
.competent_staff h1{
    color:orange;
    font-size:39px;
}
.all_competency{
    display:flex;
    justify-content: center;
    align-items: center;
}
.all_competency img{
    width:400px;
    margin:5px;
   
}

.all_competency a{
    color:blue;
    cursor: pointer;
}
@media(max-width:1029px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:700px;
     
       
    }
}
@media(max-width:740px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:600px;
        margin-left:20px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:660px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:500px;
        margin-left:30px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:570px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:500px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:545px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:450px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:496px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:400px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:450px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:380px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:430px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:360px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:405px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:340px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:386px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:320px;
        margin-left:5px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:360px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:300px;
        margin-left:5px;
     
       
    }
    .all_competency p{
        width:90%;
        text-align: center;
    }
}
@media(max-width:340px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:280px;
        margin-left:3px;
     
       
    }
    .all_competency p{
        width:90%;
        text-align: center;
    }
}