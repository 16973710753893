.re-use{
    padding: 1rem;
     background-color: #FAF9F6;
    /* background-color: var( --color-blog);*/
    
  }
  .re-use h1{
      text-align: left;
      padding:6px;
  }
  .all_subjects__div{
     display:flex;
     justify-content: space-between;
     align-items: center;
     gap:8px;
   
  }
  .all_subjects__div img{
      width:150px;
      height:100px;
     
      margin-left:5.5rem;
  }
  
  .physics{
      width:350px;
      height:400px;
      border-radius: 10px;
      padding:10px;
      background: rgba( 255, 255, 255, 0.25 );
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );
      border-radius: 10px;
      border: 1px solid rgba( 255, 255, 255, 0.18 );
  border-radius: 10px;
  
  }
  .content{
      margin-top:1em;
      margin-left:6em;
  }
  .physics h4{
      color:red;
      text-align: center;
  }
  .physics p{
      text-align:center;
      color:#042c54;
      padding:10px;
  }
  .content button{
      width:200px;
      height:40px;
      outline:none;
      border-style:none;
      cursor: pointer;
      background-color: orange;
  }
  .content button:hover{
      background-color: teal;
  }
  @media(max-width:923px){
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 450px;
        height: 320px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}
@media(max-width:680px){
    .re-use h1 {
        color: orange;
        font-size:35px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 450px;
        height: 350px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}

@media(max-width:500px){
    .re-use h1 {
        color: orange;
        font-size:35px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 400px;
        height: 370px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}

@media(max-width:430px){
    .re-use h1 {
        color: orange;
        font-size:35px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 350px;
        height: 370px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}

@media(max-width:380px){
    .re-use h1 {
        color: orange;
        font-size:35px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 320px;
        height: 390px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}


@media(max-width:345px){
    .re-use h1 {
        color: orange;
        font-size:30px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 300px;
        height: 390px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}