.library-container {
    width:100%;
    height:100%;
}

.library-container img{

  width:100%

}
/*.lib-overlay{
    width:100%;
    height:140vh;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
   
}
*/
.library-info{
    position: absolute;
    top:60%;
    left:45%;
    transform: translate(-50%,-50%);
}
.library-info h1{
    text-align: center;
    font-size:40px;
    color:red;
}
.buttons{
    display:flex;
    justify-content: center;
    align-items: center;
}
.buttons input{
    outline:none;
    border: none;
    width:250px;
    padding:10px;
  
    color:#111;
    
}
.buttons button{
    width:120px;
    height:40px;
    outline:none;
    border:none;
    cursor: pointer;
    background-color: red;
    color:white;
    border-radius: 6px;
    padding:4px;
}
.library-cards{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
@media(max-width:1029px){
   .library-cards{
    flex-wrap: wrap;
   }
}
@media(max-width:480px){
    .library-info{
        position: absolute;
        top:45%;
        left:45%;
        transform: translate(-50%,-50%);
    }

.library-info h1{
    text-align: center;
    font-size:26px;
    color:red;
    font-weight: bold;
}
.buttons{
   flex-direction: column;
}
.buttons input{
    outline:none;
    border: none;
    width:250px;
    padding:10px;
  
    color:#111;
    
}
.buttons button{
    width:220px;
    height:40px;
    outline:none;
    border:none;
    cursor: pointer;
    background-color: red;
    color:white;
    border-radius: 6px;
    padding:4px;
}
.library-container img{

    width:100%;
    height:60vh;
  
  }
}