.generic-message p{
    margin:80px;
    width:80%;
    font-size:32px;
    text-align: center;
}
@media(max-width:624px){
    .generic-message p{
        font-size: 22px;
        width:70%;
    }
}
@media(max-width:387px){
    .generic-message p{
        font-size: 22px;
        width:80%;
        margin:30px;
    }
}
