
.social-icons{
    display:flex;
    justify-content: space-around;
    align-items: center;
    padding:1rem;
}
.all_values{
  display: flex;
  width:290px;
  height:175px;
  padding:0.5rem;
   border: 2px solid orange;
  background-color:white;
  border-radius: 15px;
  filter: drop-shadow(.75em .75em .75em);
}
 h2{
 padding:6px;
 font-size:2em;
 color:orange;
}
.all_values h3{
  color:orangered;
}
.all_values p{
  color:black;
}
.social-media img{
  margin:5px;
  width:80px;
  height:80px;
  border-radius: 50%;
}
@media(max-width:1029px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    width:600px;
    height:200px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:150px;
    height:150px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:3em;
    color:orange;
   }
}

@media(max-width:680px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    width:500px;
    height:170px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:130px;
    height:130px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:2em;
    color:orange;
   }
}


@media(max-width:550px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    width:400px;
    height:170px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:130px;
    height:130px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:2em;
    color:orange;
   }
}


@media(max-width:440px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    width:350px;
    height:170px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:130px;
    height:130px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:2em;
    color:orange;
   }
}


@media(max-width:380px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    
    width:300px;
    height:220px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:130px;
    height:130px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:2em;
    color:orange;
   }
}



@media(max-width:350px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    
    width:280px;
    height:220px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:100px;
    height:100px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:30px;
    color:orange;
   }
}