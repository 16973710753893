.footer{
    background-color: #111;
}

.footer-inner{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding:2rem;
 
}
.footer-inner h2{
    color:white;
    font-size:25px;
    text-align: center;
}
.footer-inner ul li{
    list-style-type: none;
    color:white;
    text-align: center;
}
.footer-inner img{
    width:100px;
    height:100px;
    border-radius: 50%;
}
@media(max-width:1029px){
    .footer-inner h2{
        color:#FAF9F6;
        font-size:20px;
    }
    .footer-inner ul li{
        list-style-type: none;
        color:white;
        text-align: center;
    }
    .footer-inner img{
        width:100px;
        height:100px;
        border-radius: 50%;
    }
}
@media(max-width:940px){
    .footer-inner{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding:2rem;
        
    }
    .Programs{
        margin-left:6px;
    }
    .footer-inner h2{
        color:#FAF9F6;
        font-size:20px;
    }
    .footer-inner ul li{
        list-style-type: none;
        color:white;
        text-align: center;
    }
    .footer-inner img{
        width:100px;
        height:100px;
        border-radius: 50%;
    }
}
@media(max-width:923px){
    .footer-inner{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding:2rem;
        
    }
    .Programs{
        margin-left:10px;
    }
    .footer-inner h2{
        color:#FAF9F6;
        font-size:20px;
        text-align: center;
    }
    .footer-inner ul li{
        list-style-type: none;
        color:white;
        text-align: center;
    }
    .footer-inner img{
        width:100px;
        height:100px;
        border-radius: 50%;
    }
}
@media(max-width:805px){
    .footer-inner{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        padding:2rem;
        
    }
    .Programs{
        margin-left:10px;
    }
    .footer-inner h2{
        color:#FAF9F6;
        font-size:20px;
        text-align: center;
    }
    .footer-inner ul li{
        list-style-type: none;
        color:white;
        text-align: center;
    }
    .footer-inner img{
        width:100px;
        height:100px;
        border-radius: 50%;
    }
}