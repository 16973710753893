.Sage__web__navbar{
    display:flex;
    justify-content:center;
    align-items: center;
  
    font-family:var(--font-family);
   
 
 /*
    position:absolute;
    top:0%;
    z-index: 999;
    */
    width:100%;
    
    
}
.Sage__web__logo{
display: flex;
 

}
.Sage__web__logo img{
    width:100px;
 height:100px;
 
}
.Sage__web__logo a{
    text-decoration: none;
}
.Sage__web__links {
 display:flex;
 justify-content: flex-start;
 flex-direction: row;
 align-items: center;
 margin: 1rem 0;
}
.Sage__web__links a {
    text-decoration: none;
    color:red;
    margin-left: 1rem;

   }
   .Sage__web__links a :hover{
    color:tomato;
   }
.Sage__web__sign_up{
 display: flex;
 justify-content: flex-end;
 align-items: center;
 margin-left: 1rem;
}
.Sage__web__sign_up button{
    color:#fff;
    font-weight: bold;
    font-size:23px;
    width:180px;
    height:40px;
    background-color:blue;
    border-radius: 6px;
    outline: none;
    border-style:none;
    font-family:var(--font-family);
    margin-left: 0.5rem;
}

.Sage__web__sign_up button:hover{
    background-color:red;
    color:white;
    cursor: pointer;
}
.Sage__web__sign_up p{
    font-size:23px;
    font-family: var(--font-family);
    color:#fff;
}
.Sage__web__navbar__menu{
    display:none;
    color:red;
    background-color: #ffff;
    width:100%; 
    /*
  position: absolute;
     top:0%;
    z-index: 999;
    */
  
    
}
.admins{
    position: absolute;
    top:50px;
    left:38%;
    right:0;
 
}
.list-admins ul{
   
 
    color:#111;
}
.list-admins li{
    list-style-type: none;
}
.hover:hover .admins{
    display:block;
}
.Sage__web__links .active{
    border-bottom: 2px solid red;
}
.Sage__web__mobile__links{
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    color:red;
    font-size:16px;
    cursor: pointer;
    pointer-events: none;
    margin-right:0.7rem;
    font-family: var(--font-family);
    
}

.Sage__web__mobile__links .active{
    border-bottom: 2px solid black;
}
.Sage__web__sign__buttons{
    display:block;
}
.Sage__web__logo__min{
    display: flex;
    width:100px;
    height:50px;
    margin:0.5rem;
}
@media(max-width:1029px){
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    
    
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-start;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    .Sage__web__logo__min a{
        text-decoration: none;
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:center;
        flex-direction: row;
        margin:5px;
        position: absolute;
        top:10%;
        left:93%;
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:65%;
        width:300px;
        background-color: #eee;
     transition: 2s ease-in;
     border-radius: 6px;
     z-index: 1;
    }
    .mobile-links-nav p{
        margin-right:100px;
        padding:4px;
        font-size:20px;
    
      
    }
   .mobile-links-nav a{
        text-decoration:none;
        color:red;
        cursor: pointer;
        pointer-events: all;
        
    }
 
    .mobile-links-nav a:hover{
        color:tomato;
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .contact{
        cursor: pointer;
        margin-top:10px;
       
    }
    .contact:hover{
        background-color: red;
       
    }
}
p .dashboard{
    color:blue;
}

@media(max-width:999px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:93%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:66%;
        width:300px;
        background-color: #eee;
        border-radius: 6px;

    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}


@media(max-width:900px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:64%;
        width:300px;
        background-color: #eee;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}



@media(max-width:859px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:60%;
        width:300px;
        background-color: #eee;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}
@media(max-width:780px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:55%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}


@media(max-width:700px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:50%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}

@media(max-width:656px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:50%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}

@media(max-width:625px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:45%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:#111;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}


@media(max-width:570px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:40%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}



@media(max-width:522px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:35%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}




@media(max-width:482px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:30%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}



@media(max-width:450px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:25%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}


@media(max-width:423px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:87%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:20%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}



@media(max-width:394px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:87%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:15%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}





@media(max-width:375px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:87%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:10%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}
@media(max-width:352px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:87%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:5%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}

@media(max-width:335px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:85%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:3%;
        width:290px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}