
.library-details-container{
    margin:30px;
    width:350px;
    height:452px;
    padding:15px 0 15px 0;
    border-radius: 6px;
    background: rgba( 255, 255, 255, 0.25 );
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );

}
.library-details-container:hover{
    transition: 1s ;
  
}
.library-details-container img{
    width:350px;
    height:200px;
}
.library-details-container h4{
    color:red;
    text-align: center;
}
.library-details-container p{
    color:#111;
    width:100%;
    padding:4px;
}
.library-details-container button{
    width:345px;
    height:40px;
    display: inline-block;
    color:white;
    background-color: red;
    cursor: pointer;
    border: none;
    outline:none;
    padding:4px;
    border-radius: 6px;
    margin:3px;
}

@media(max-width:380px){
   
.library-details-container{
    margin:30px;
    width:300px;
    height:452px;
    padding:15px 0 15px 0;
    border-radius: 6px;
    background: rgba( 255, 255, 255, 0.25 );
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );

}
.library-details-container:hover{
    transition: 1s ;
  
}
.library-details-container img{
    width:300px;
    height:200px;
}
.library-details-container h4{
    color:red;
    text-align: center;
}
.library-details-container p{
    color:#111;
    width:100%;
    padding:4px;
}
.library-details-container button{
    width:285px;
    height:40px;
    display: inline-block;
    color:white;
    background-color: red;
    cursor: pointer;
    border: none;
    outline:none;
    padding:4px;
    border-radius: 6px;
    margin:3px;
} 
}

