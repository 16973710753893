
.sub-nav-bar{
    display:flex;
    justify-content: space-between;
    background-color: teal;
    align-items: center;
}
.sub-nav-bar h1{
    color:orange;
}
.the-links-to-logins-plus-register{
    display:flex;
    justify-content: space-between;
    align-items: center;
    
}
.the-links-to-logins-plus-register p{
    color:black;
}
.the-links-to-logins-plus-register a{
    text-decoration: none;
    color:orange;
    margin-right:10px;
}
.sub-nav-bar button{
    color:#fff;
    width:200px;
    height:40px;
    background-color:red;
    border-radius: 6px;
    outline: none;
    border-style:none;
    font-family:var(--font-family);
    font-size: 22px;
    font-weight: 600;
    margin: 1rem;
}
.sub-nav-bar button:hover{
    background-color: orange;
}