
.form-all{
    margin:2rem;
}
 form{
     position: absolute;
     top:15%;
     left:30%;
     background-color: white;
    margin-left:7rem;
    
     padding:1.5rem;
     box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );
   opacity:0.8;
  align-items:center;
    
}

label{
     padding:10px;
    color:orange;
    font-size:15px;
}
input{
      width:100%;
     padding:5px;
}
.enroll-all button{
    width:360px;
    height:40px;
    background-color: red;
    color:white;
    outline:none;
    border-style: none;
    
    cursor: pointer;
    border-radius: 6px;
}
.enroll-all button:hover{
    background-color: orange;
}


@media(max-width:872px){
    form{
        position: absolute;
        top:12%;
        left:25%;
        background-color: white;
       margin-left:2rem;
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100%;
        padding:5px;
   }
   .enroll-all button{
       width:360px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}

@media(max-width:700px){
    form{
        position: absolute;
        top:12%;
        left:10%;
        background-color: white;
       margin-left:2rem;
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100%;
        padding:5px;
   }
   .enroll-all button{
       width:360px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}


@media(max-width:600px){
    form{
        position: absolute;
        top:12%;
        left:5%;
        background-color: white;
       margin-left:4rem;
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100%;
        padding:5px;
   }
   .enroll-all button{
       width:360px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}


@media(max-width:540px){
    form{
        position: absolute;
        top:12%;
        left:-2%;
        background-color: white;
       margin-left:4rem;
      
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100%;
        padding:5px;
   }
   .enroll-all button{
       width:360px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}


@media(max-width:509px){
    .form-all{
        width:350px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-2%;
        background-color: white;
       margin-left:4rem;
      
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100px;
        padding:5px;
   }
   .enroll-all button{
       width:300px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}


@media(max-width:440px){
    .form-all{
        width:350px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-6%;
        background-color: white;
       margin-left:4rem;
      
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100px;
        padding:5px;
   }
   .enroll-all button{
       width:300px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}

@media(max-width:419px){
    .form-all{
        width:350px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-1%;
        background-color: white;
       margin-left:1.5rem;
      
        padding:1rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100px;
        padding:5px;
   }
   .enroll-all button{
       width:320px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}

@media(max-width:400px){
    .form-all{
        width:350px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-6%;
        background-color: white;
       margin-left:1.5rem;
      
        padding:1rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100px;
        padding:5px;
   }
   .enroll-all button{
       width:320px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}
@media(max-width:388px){
    .form-all{
        width:340px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-5%;
        background-color: white;
       margin-left:1.1rem;
      
      
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100px;
        padding:5px;
   }
   .enroll-all button{
       width:315px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}

@media(max-width:370px){
    .form-all{
        width:320px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-5%;
        background-color: white;
       margin-left:1.1rem;
      
      
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:80px;
        padding:5px;
   }
   .enroll-all button{
       width:290px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}
@media(max-width:350px){
    .form-all{
        width:300px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-5%;
        background-color: white;
       margin-left:1.1rem;
      
      
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:60px;
        padding:5px;
   }
   .enroll-all button{
       width:270px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}
@media(max-width:332px){
    .form-all{
        width:280px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-5%;
        background-color: white;
       margin-left:1.1rem;
      
      
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:50px;
        padding:5px;
   }
   .enroll-all button{
       width:250px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}