
.ai{
    background-color: teal;
    color:white;
  
}
.ai-big{
    display:flex;
    justify-content: center;
    align-items: center;
}
.ai-big img{
    width:500px;
}
.ai-big button{
    width:230px;
    height:45px;
    outline:none;
    border-style: none;
    background-color: red;
    border-radius: 12px;
    color:white;
  cursor: pointer;
}
.ai p{
    width:75%;
}
@media(max-width:1029px){
    .ai p{
        width:100%;
    } 
}
@media(max-width:820px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:500px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:250px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}

@media(max-width:675px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:500px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:200px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}

@media(max-width:600px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:500px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:150px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}

@media(max-width:550px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:400px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:100px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}
@media(max-width:530px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:400px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:60px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}

@media(max-width:380px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:350px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:50px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}

@media(max-width:360px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:350px;
    }
    .ai-big button{
        width:200px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:45px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}