.sidebar-item {
    padding: 0.75em 1em;
    display: block;
    /* border-radius: 5px;
    // border-bottom: 2px solid #ccc;
    */
    background-color: rgba(255, 255, 255, 0.1);
    transition: background-color 0.15s;
  }
  
  .s-parent {
    border-bottom: 2px solid #ccc;
  }
  
  .sidebar-item:hover {
    background-color: #eee;
  }
  
  .sidebar-title {
    display: flex;
    font-size: 1.2em;
    justify-content: space-between;
    align-items: center;
  }
  
  .sidebar-title span {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    transition: all 0.5s;
    font-size: 1.6rem;
  }
  
  .sidebar-title span .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    font-size: 25px;
  }
  
  .sidebar-title .arrow-icon {
    cursor: pointer;
    transition: all 0.5s;
  }
  
  .sidebar-item.open > .sidebar-title .arrow-icon {
    transform: rotate(90deg);
  }
  
  .sidebar-content {
    padding-top: 0.25em;
    height: 0;
    overflow: hidden;
  }
  .sidebar-item.open > .sidebar-content {
    height: auto;
  }
  
  .s-child {
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-top: 5px;
  }
  
  .s-child:hover {
    border: 1px solid blue;
  }
  

  
  .active {
    color: red;
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    background-color: #eee;
  }
  
  .active::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    right: 0;
    top: 0;
    background-color: green;
  }
  
  .sublink .sidebar-item .sidebar-title {
    padding-left: 40px;
  }