
*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}


body {
  font-size:18px;
  overflow-x: hidden;
}
a{
  text-decoration: none;
  cursor: pointer;
}
input{
  outline: none;
}
/*
:root {
  --nav-width: 68px;
  --header-height: 45px;

  --font-family: "Poppins", sans-serif;
  --dark-blue: #0a1930;
  --light-blue: #1f93ff;

  --color-white: #fff;
  --color-dark: #333;

  --color-grey: #eee;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  --color-purple: #9d0191;
  --color-orange: #ff7722;

  --color-primary: #007bff;
  --color-success: #28a745;
  --color-danger: orangered;
}
*/