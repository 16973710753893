.video_lectures_for_free{
   
    width:100%;
    padding:1rem;
}

.real_video_lessons{
    display:flex;
    justify-content: space-between;
    align-items: center;
    
}
.video_lectures_for_free h1{
    text-align: left;
    color:red;
    font-size:45px;
}
.real_video_lessons video{
    width:400px;
    margin-left:10px;
}
.real_video_lessons p{
    color:black;
}