.boards{
    padding:2rem;

}
.boards h1{
    color:orange;
}
.boards-lay-out{
    display:flex;
    justify-content: center;
    align-items: center;
    gap:12px;
}
.boards-lay-out h3{
    text-align: center;
}
.boards h1{
    text-align: center;
    padding:5px;
}
.glass{
    width:480px;
    height:430px;
    border-radius: 19px;
    background: rgba( 255, 255, 255, 0.6 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
 
 
    padding:10px;
    
}
.glass img{
    width:200px;
    height:200px;
    border-radius:50%;
    margin-left:5rem;
    padding:10px;
    background-color: orange;
    border:2px solid red;
}
/*.blue-layer{
    background-color: #1034A6;
}
*/
.list_style{
   padding:0;
   margin:0;
    text-align: center;
}
.none li{
    list-style-type: none;
   
}
.glass button{
    width:200px;
    height:50px;
    outline:none;
    border-style:none;
    background-color: orange;
    cursor: pointer;
    color:white;
    padding:10px;
    border-radius:25px;
    margin-left:5rem;
}
.glass a{
    text-decoration: none;
}
.glass button:hover{
    background-color: red;
}
.glass-content{
    display:flex;
    background-color:white;
   filter: drop-shadow(.75em .75em .75em);
    gap:6px;
    padding:1rem;
}
.glass-content p{
    color:#111;
    width:100%;
  
}
.glass-content img{
    width:50px;
    height:50px;
    border-radius: 50%;
}
/*.glass img{
    width:379px;
    height:200px;
    
}
*/
@media(max-width:800px){
    .boards-lay-out{
        flex-direction: column;
    }
}

@media(max-width:524px){
    .glass{
        width:400px;
        height:400px;
    }
}
@media(max-width:430px){
    .glass{
        width:350px;
        height:320px;
    }
    .glass img{
        width:150px;
        height:150px;
        border-radius:50%;
        margin-left:5rem;
        padding:10px;
        background-color: orange;
        border:2px solid red;
    }
    .glass button{
        width:200px;
        height:50px;
        outline:none;
        border-style:none;
        background-color: orange;
        cursor: pointer;
        color:white;
        padding:10px;
        border-radius:25px;
        margin-left:3rem;
    }
}
@media(max-width:430px){
    .glass{
        width:330px;
        height:320px;
    }
    .glass img{
        width:150px;
        height:150px;
        border-radius:50%;
        margin-left:5rem;
        padding:10px;
        background-color: orange;
        border:2px solid red;
    }
    .glass button{
        width:200px;
        height:50px;
        outline:none;
        border-style:none;
        background-color: orange;
        cursor: pointer;
        color:white;
        padding:10px;
        border-radius:25px;
        margin-left:3rem;
    }
}

@media(max-width:355px){
    .glass{
        width:300px;
        height:320px;
    }
    .glass img{
        width:150px;
        height:150px;
        border-radius:50%;
        margin-left:5rem;
        padding:10px;
        background-color: orange;
        border:2px solid red;
    }
    .glass button{
        width:200px;
        height:50px;
        outline:none;
        border-style:none;
        background-color: orange;
        cursor: pointer;
        color:white;
        padding:10px;
        border-radius:25px;
        margin-left:3rem;
    }
}

@media(max-width:330px){
    .glass{
        width:280px;
        height:320px;
    }
    .glass img{
        width:150px;
        height:150px;
        border-radius:50%;
        margin-left:3rem;
        padding:10px;
        background-color: orange;
        border:2px solid red;
    }
    .glass button{
        width:200px;
        height:50px;
        outline:none;
        border-style:none;
        background-color: orange;
        cursor: pointer;
        color:white;
        padding:10px;
        border-radius:25px;
        margin-left:2rem;
    }
}