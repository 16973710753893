.roundedCard{
    padding:1rem;
    background-color:#042c54;
}
.display{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
}
.display img{
    width:600px;
}
.list_style{
    
    padding:5px;
}
.list-content h4{
    color:orange;
    font-size:30px;
}
.list_style h3{
    list-style-type: none;
    font-size:17px;
    color:orange;
}
@media(max-width:993px){
    .display img{
        width:500px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:18px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:25px;
        text-align: center;
    }
}
@media(max-width:922px){
    .display img{
        width:500px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:17px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:25px;
        text-align: center;
    }
}
@media(max-width:903px){
    .display{
        flex-direction: column;
    }
    .display img{
        width:500px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:20px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:25px;
        text-align: center;
    }
}
@media(max-width:550px){
    .display{
        flex-direction: column;
    }
    .display img{
        width:400px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:20px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:25px;
        text-align: center;
    }
}
@media(max-width:463px){
    .display{
        flex-direction: column;
    }
    .display img{
        width:400px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:17px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:25px;
        text-align: center;
    }
}
@media(max-width:412px){
    .display{
        flex-direction: column;
    }
    .display img{
        width:300px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:16px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:22px;
        text-align: center;
    }
}