.robotics-mechanics-ai{
    padding:2rem;
    background-color: white;
}
.video-plus-tex{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.video-plus-tex video{
    width:600px;
    
}
.video-plus-tex h1{
    color:orange;
    font-size:23px;
    text-align: center;

}
.data-plus-content{
    margin-left:30px;
}
.data-plus-content p{
    padding:7px;
   
}
.data-plus-content button{
    width:230px;
    height:40px;
    cursor: pointer;
    background-color: red;
    color:white;
    padding:10px;
    font-size:20px;
    border-style: none;
    outline:none;
    border-radius: 8px;
    margin-left:120px;
}
@media(max-width:954px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:600px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}
@media(max-width:645px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:500px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .ai-header{
        font-size:22px;
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}
@media(max-width:525px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:400px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .ai-header{
        font-size:22px;
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}
@media(max-width:430px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:370px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .ai-header{
        font-size:22px;
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}
@media(max-width:398px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:340px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .ai-header{
        font-size:22px;
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}
@media(max-width:360px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:298px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .ai-header{
        font-size:22px;
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}