
*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}


body {
  font-size:18px;
  overflow-x: hidden;
}
a{
  text-decoration: none;
  cursor: pointer;
}
input{
  outline: none;
}
/*
:root {
  --nav-width: 68px;
  --header-height: 45px;

  --font-family: "Poppins", sans-serif;
  --dark-blue: #0a1930;
  --light-blue: #1f93ff;

  --color-white: #fff;
  --color-dark: #333;

  --color-grey: #eee;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  --color-purple: #9d0191;
  --color-orange: #ff7722;

  --color-primary: #007bff;
  --color-success: #28a745;
  --color-danger: orangered;
}
*/
.Sage__web__navbar{
    display:flex;
    justify-content:center;
    align-items: center;
  
    font-family:var(--font-family);
   
 
 /*
    position:absolute;
    top:0%;
    z-index: 999;
    */
    width:100%;
    
    
}
.Sage__web__logo{
display: flex;
 

}
.Sage__web__logo img{
    width:100px;
 height:100px;
 
}
.Sage__web__logo a{
    text-decoration: none;
}
.Sage__web__links {
 display:flex;
 justify-content: flex-start;
 flex-direction: row;
 align-items: center;
 margin: 1rem 0;
}
.Sage__web__links a {
    text-decoration: none;
    color:red;
    margin-left: 1rem;

   }
   .Sage__web__links a :hover{
    color:tomato;
   }
.Sage__web__sign_up{
 display: flex;
 justify-content: flex-end;
 align-items: center;
 margin-left: 1rem;
}
.Sage__web__sign_up button{
    color:#fff;
    font-weight: bold;
    font-size:23px;
    width:180px;
    height:40px;
    background-color:blue;
    border-radius: 6px;
    outline: none;
    border-style:none;
    font-family:var(--font-family);
    margin-left: 0.5rem;
}

.Sage__web__sign_up button:hover{
    background-color:red;
    color:white;
    cursor: pointer;
}
.Sage__web__sign_up p{
    font-size:23px;
    font-family: var(--font-family);
    color:#fff;
}
.Sage__web__navbar__menu{
    display:none;
    color:red;
    background-color: #ffff;
    width:100%; 
    /*
  position: absolute;
     top:0%;
    z-index: 999;
    */
  
    
}
.admins{
    position: absolute;
    top:50px;
    left:38%;
    right:0;
 
}
.list-admins ul{
   
 
    color:#111;
}
.list-admins li{
    list-style-type: none;
}
.hover:hover .admins{
    display:block;
}
.Sage__web__links .active{
    border-bottom: 2px solid red;
}
.Sage__web__mobile__links{
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    color:red;
    font-size:16px;
    cursor: pointer;
    pointer-events: none;
    margin-right:0.7rem;
    font-family: var(--font-family);
    
}

.Sage__web__mobile__links .active{
    border-bottom: 2px solid black;
}
.Sage__web__sign__buttons{
    display:block;
}
.Sage__web__logo__min{
    display: flex;
    width:100px;
    height:50px;
    margin:0.5rem;
}
@media(max-width:1029px){
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    
    
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-start;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    .Sage__web__logo__min a{
        text-decoration: none;
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:center;
        flex-direction: row;
        margin:5px;
        position: absolute;
        top:10%;
        left:93%;
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:65%;
        width:300px;
        background-color: #eee;
     transition: 2s ease-in;
     border-radius: 6px;
     z-index: 1;
    }
    .mobile-links-nav p{
        margin-right:100px;
        padding:4px;
        font-size:20px;
    
      
    }
   .mobile-links-nav a{
        text-decoration:none;
        color:red;
        cursor: pointer;
        pointer-events: all;
        
    }
 
    .mobile-links-nav a:hover{
        color:tomato;
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .contact{
        cursor: pointer;
        margin-top:10px;
       
    }
    .contact:hover{
        background-color: red;
       
    }
}
p .dashboard{
    color:blue;
}

@media(max-width:999px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:93%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:66%;
        width:300px;
        background-color: #eee;
        border-radius: 6px;

    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}


@media(max-width:900px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:64%;
        width:300px;
        background-color: #eee;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}



@media(max-width:859px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:60%;
        width:300px;
        background-color: #eee;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}
@media(max-width:780px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:55%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}


@media(max-width:700px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:50%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}

@media(max-width:656px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:50%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}

@media(max-width:625px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:45%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:#111;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}


@media(max-width:570px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:40%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}



@media(max-width:522px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:35%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}




@media(max-width:482px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:30%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}



@media(max-width:450px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:90%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:25%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        margin-bottom: 30px;
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}


@media(max-width:423px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:87%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:20%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}



@media(max-width:394px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:87%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:15%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}





@media(max-width:375px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:87%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:10%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}
@media(max-width:352px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:87%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:5%;
        width:300px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}

@media(max-width:335px){
   
    .Sage__web__links{
        display: none;
    }
    .Sage__web__logo{
        display: none;
    }
    .Sage__web__navbar__menu{
        display: flex;
        display:block;
        justify-content: flex-end;
        align-items:center;
        font-size: 16px;
        padding:2rem;
       
    }
    .menu_Mobile{
        display:flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: row;
        position: absolute;
        top:10%;
        left:85%;
        margin:5px;
       
    }
    .mobile-links-nav{
        position: absolute;
        top:20.5%;
        left:3%;
        width:290px;
        background-color: #eee;
        z-index: 1;
        border-radius: 6px;
    }
    .Sage__web__logo__min img{
        width:100px;
        height:80px;
        
    }
    a{
        text-decoration: none;
        color:red;
    }
    a:hover{
        color:orange
    }
    .Sage__web__sign_up{
        display:flex;
        flex-direction: column;
        padding:10px;
        margin-left:30px;
    }
    .Sage__web__sign_up button{
        margin-top:10px;
       
    }
}
.video{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
     z-index: 999;
}
.video video{
    width:100%;
    height:100%;
    pointer-events: none;
}
.video-overlay{
    position: absolute;
    top:70%;
    left:40%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}
.video-overlay h1{
    color:white;
}
.video-overlay .lesson{
    width:150px;
    height:45px;
    outline:0;
    border-radius: 20px;
    border-style: none;
    cursor: pointer;
    color:white;
    background-color: red;
   margin:6px;
}
.video-overlay .lesson:hover{
    background-color: orange;
}
.video-overlay .register{
    width:190px;
    height:35px;
    outline:0;
    border-style: none;
    cursor: pointer;
    border-radius: 12px;
    color:white;
    background-color: blue;
}
.video-overlay a{
    text-decoration: none;
}
.video-overlay .register:hover{
    background-color: teal;
}

.video-overlay .bold{
    font-size:45px;
    font-weight: bold;
}
.video-overlay .big{
    font-size:26px;
    font-weight: bold;
}
@media(max-width:1029px){
    .video-overlay{
        margin-left:3rem;
    }
}
@media(max-width:672px){
    .video-overlay{
        position: absolute;
        top:45%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:35px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:20px;
        font-weight: bold;
    }
}
@media(max-width:656px){
    .video video{
        width:150%;
        
        pointer-events: none;
    }
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:35px;
        font-weight: bold;
        width:100%;
    }
    .video-overlay .big{
        font-size:20px;
        font-weight: bold;
    }
}
@media(max-width:619px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
}
@media(max-width:586px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
}
@media(max-width:557px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:33px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:21px;
        font-weight: bold;
    }
}
@media(max-width:535px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:33px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:21px;
        font-weight: bold;
    }
}
@media(max-width:518px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:29px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:18px;
        font-weight: bold;
    }
    .video-overlay .lesson{
        width:130px;
        height:35px;
        outline:0;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:120px;
        height:25px;
        outline:0;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
    }
}
@media(max-width:459px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:25px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:18px;
        font-weight: bold;
    }
    .video-overlay .lesson{
        width:120px;
        height:35px;
        outline:0;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:100px;
        height:35px;
        outline:0;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
    }
}
@media(max-width:450px){
    .video video{
        width:180%;
        
        pointer-events: none;
    }
}
@media(max-width:416px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:25px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:19px;
        font-weight: bold;
    }
    .video-overlay .lesson{
        width:200px;
        height:45px;
        outline:0;
        font-size:18px;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:200px;
        height:35px;
        outline:0;
        font-size:18px;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
        margin-left:5px;
    }
}
@media(max-width:406px){
    .video-overlay{
        position: absolute;
        top:60%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:25px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:19px;
        font-weight: bold;
    }
    .video-overlay .lesson{
        width:200px;
        height:45px;
        outline:0;
        font-size:18px;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:200px;
        height:35px;
        outline:0;
        font-size:18px;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
        margin-left:5px;
    }
}

@media(max-width:400px){
    .video-overlay{
        position: absolute;
        top:55%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:23px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:17px;
        font-weight: bold;
    }
    .video-overlay .lesson{
        width:200px;
        height:45px;
        outline:0;
        font-size:18px;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:200px;
        height:35px;
        outline:0;
        font-size:18px;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
        margin-left:5px;
    }
}

@media(max-width:384px){
    .video-overlay{
        position: absolute;
        top:55%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:23px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:18px;
        font-weight: bold;
     
    }
    .video-overlay .lesson{
        width:200px;
        height:45px;
        outline:0;
        font-size:16px;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:200px;
        height:35px;
        outline:0;
        font-size:16px;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
    }
}


@media(max-width:383px){
    .video-overlay{
        position: absolute;
        top:55%;
        left:30%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    } 
    .video-overlay .bold{
        font-size:23px;
        font-weight: bold;
    }
    .video-overlay .big{
        font-size:18px;
        font-weight: bold;
        
    }
    .video-overlay .lesson{
        width:200px;
        height:45px;
        outline:0;
        font-size:16px;
        border-radius: 20px;
        border-style: none;
        cursor: pointer;
        color:white;
        background-color: red;
       margin:6px;
    }
    .video-overlay .register{
        width:200px;
        height:35px;
        outline:0;
        font-size:16px;
        border-style: none;
        cursor: pointer;
        border-radius: 12px;
        color:white;
        background-color: blue;
    }
}
@media(max-width:375px){
    .video video{
        width:200%;
        
        pointer-events: none;
    }
}
@media(max-width:340px){
    .video video{
        width:230%;
        
        pointer-events: none;
    }
}

.social-icons{
    display:flex;
    justify-content: space-around;
    align-items: center;
    padding:1rem;
}
.all_values{
  display: flex;
  width:290px;
  height:175px;
  padding:0.5rem;
   border: 2px solid orange;
  background-color:white;
  border-radius: 15px;
  -webkit-filter: drop-shadow(.75em .75em .75em);
          filter: drop-shadow(.75em .75em .75em);
}
 h2{
 padding:6px;
 font-size:2em;
 color:orange;
}
.all_values h3{
  color:orangered;
}
.all_values p{
  color:black;
}
.social-media img{
  margin:5px;
  width:80px;
  height:80px;
  border-radius: 50%;
}
@media(max-width:1029px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    width:600px;
    height:200px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:150px;
    height:150px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:3em;
    color:orange;
   }
}

@media(max-width:680px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    width:500px;
    height:170px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:130px;
    height:130px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:2em;
    color:orange;
   }
}


@media(max-width:550px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    width:400px;
    height:170px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:130px;
    height:130px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:2em;
    color:orange;
   }
}


@media(max-width:440px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    width:350px;
    height:170px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:130px;
    height:130px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:2em;
    color:orange;
   }
}


@media(max-width:380px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    
    width:300px;
    height:220px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:130px;
    height:130px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:2em;
    color:orange;
   }
}



@media(max-width:350px){
  .social-icons{
    flex-direction: column;
  }
  .all_values{
    
    width:280px;
    height:220px;
    margin: 10px;
  }
  .social-media img{
    margin:5px;
    width:100px;
    height:100px;
    border-radius: 50%;
  }
  h2{
    padding:6px;
    font-size:30px;
    color:orange;
   }
}
.boards{
    padding:2rem;

}
.boards h1{
    color:orange;
}
.boards-lay-out{
    display:flex;
    justify-content: center;
    align-items: center;
    grid-gap:12px;
    gap:12px;
}
.boards-lay-out h3{
    text-align: center;
}
.boards h1{
    text-align: center;
    padding:5px;
}
.glass{
    width:480px;
    height:430px;
    border-radius: 19px;
    background: rgba( 255, 255, 255, 0.6 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
 
 
    padding:10px;
    
}
.glass img{
    width:200px;
    height:200px;
    border-radius:50%;
    margin-left:5rem;
    padding:10px;
    background-color: orange;
    border:2px solid red;
}
/*.blue-layer{
    background-color: #1034A6;
}
*/
.list_style{
   padding:0;
   margin:0;
    text-align: center;
}
.none li{
    list-style-type: none;
   
}
.glass button{
    width:200px;
    height:50px;
    outline:none;
    border-style:none;
    background-color: orange;
    cursor: pointer;
    color:white;
    padding:10px;
    border-radius:25px;
    margin-left:5rem;
}
.glass a{
    text-decoration: none;
}
.glass button:hover{
    background-color: red;
}
.glass-content{
    display:flex;
    background-color:white;
   -webkit-filter: drop-shadow(.75em .75em .75em);
           filter: drop-shadow(.75em .75em .75em);
    grid-gap:6px;
    gap:6px;
    padding:1rem;
}
.glass-content p{
    color:#111;
    width:100%;
  
}
.glass-content img{
    width:50px;
    height:50px;
    border-radius: 50%;
}
/*.glass img{
    width:379px;
    height:200px;
    
}
*/
@media(max-width:800px){
    .boards-lay-out{
        flex-direction: column;
    }
}

@media(max-width:524px){
    .glass{
        width:400px;
        height:400px;
    }
}
@media(max-width:430px){
    .glass{
        width:350px;
        height:320px;
    }
    .glass img{
        width:150px;
        height:150px;
        border-radius:50%;
        margin-left:5rem;
        padding:10px;
        background-color: orange;
        border:2px solid red;
    }
    .glass button{
        width:200px;
        height:50px;
        outline:none;
        border-style:none;
        background-color: orange;
        cursor: pointer;
        color:white;
        padding:10px;
        border-radius:25px;
        margin-left:3rem;
    }
}
@media(max-width:430px){
    .glass{
        width:330px;
        height:320px;
    }
    .glass img{
        width:150px;
        height:150px;
        border-radius:50%;
        margin-left:5rem;
        padding:10px;
        background-color: orange;
        border:2px solid red;
    }
    .glass button{
        width:200px;
        height:50px;
        outline:none;
        border-style:none;
        background-color: orange;
        cursor: pointer;
        color:white;
        padding:10px;
        border-radius:25px;
        margin-left:3rem;
    }
}

@media(max-width:355px){
    .glass{
        width:300px;
        height:320px;
    }
    .glass img{
        width:150px;
        height:150px;
        border-radius:50%;
        margin-left:5rem;
        padding:10px;
        background-color: orange;
        border:2px solid red;
    }
    .glass button{
        width:200px;
        height:50px;
        outline:none;
        border-style:none;
        background-color: orange;
        cursor: pointer;
        color:white;
        padding:10px;
        border-radius:25px;
        margin-left:3rem;
    }
}

@media(max-width:330px){
    .glass{
        width:280px;
        height:320px;
    }
    .glass img{
        width:150px;
        height:150px;
        border-radius:50%;
        margin-left:3rem;
        padding:10px;
        background-color: orange;
        border:2px solid red;
    }
    .glass button{
        width:200px;
        height:50px;
        outline:none;
        border-style:none;
        background-color: orange;
        cursor: pointer;
        color:white;
        padding:10px;
        border-radius:25px;
        margin-left:2rem;
    }
}
.brands-main{
    padding: 1rem;

}
.brands-main h1{
    text-align: center;
    font-size:45px;
    color:orange;
}
.brand-images{
    display: flex;
  justify-content: space-evenly;
    align-items: center;
  
}
.brand-images img{
    width:200px;
    height: 200px;
    border-radius: 50%;
}
@media(max-width:800px){
    .brand-images{
        flex-direction: column;
    }
    .brand-images img{
        width:400px;
        height: 400px;
        border-radius: 50%;
    }
}
@media(max-width:500px){
    .brands-main h1{
        text-align: center;
        font-size:35px;
        color:orange;
    }
    .brand-images{
        flex-direction: column;
    }
    .brand-images img{
        width:300px;
        height: 300px;
        border-radius: 50%;
    }
}
@media(max-width:330px){
    .brands-main h1{
        text-align: center;
        font-size:30px;
        color:orange;
    }
    .brand-images{
        flex-direction: column;
    }
    .brand-images img{
        width:250px;
        height: 250px;
        border-radius: 50%;
    }
}
.re-use {
    padding: 1rem;
    background-color: #FAF9F6;
    /* background-color: var( --color-blog);*/

}

.re-use h1 {
    text-align: left;
    padding: 6px;
}

.all_subjects__div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;

}

.re-use h1 {
    color: orange;
}

.all_subjects__div img {
    width: 150px;
    height: 100px;

    margin-left: 5.5rem;
}

.physics {
    width: 350px;
    height: 400px;
    border-radius: 10px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 10px;
     transition: 0.2s ease-in-out;
}

.physics:hover {
    -webkit-transform:scaleY(1.1);
            transform:scaleY(1.1);
}

.content {
    margin-top: 1em;
    margin-left: 6em;
}

.physics h4 {
    color: red;
    text-align: center;
}

.physics p {
    text-align: center;
    color: #042c54;
    padding: 10px;
}

.content button {
    width: 200px;
    height: 40px;
    outline: none;
    border-style: none;
    cursor: pointer;
    background-color: orange;
}

.content button:hover {
    background-color: teal;
}
@media(max-width:923px){
    .re-use h1 {
        color: orange;
        font-size:45px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 650px;
        height: 320px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}

@media(max-width:680px){
    .re-use h1 {
        color: orange;
        font-size:45px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 450px;
        height: 350px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}

@media(max-width:500px){
    .re-use h1 {
        color: orange;
        font-size:35px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 400px;
        height: 370px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}


@media(max-width:430px){
    .re-use h1 {
        color: orange;
        font-size:35px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 350px;
        height: 370px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}


@media(max-width:380px){
    .re-use h1 {
        color: orange;
        font-size:35px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 320px;
        height: 390px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}

@media(max-width:345px){
    .re-use h1 {
        color: orange;
        font-size:30px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 300px;
        height: 390px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}
.re-use{
    padding: 1rem;
     background-color: #FAF9F6;
    /* background-color: var( --color-blog);*/
    
  }
  .re-use h1{
      text-align: left;
      padding:6px;
  }
  .all_subjects__div{
     display:flex;
     justify-content: space-between;
     align-items: center;
     grid-gap:8px;
     gap:8px;
   
  }
  .all_subjects__div img{
      width:150px;
      height:100px;
     
      margin-left:5.5rem;
  }
  
  .physics{
      width:350px;
      height:400px;
      border-radius: 10px;
      padding:10px;
      background: rgba( 255, 255, 255, 0.25 );
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );
      border-radius: 10px;
      border: 1px solid rgba( 255, 255, 255, 0.18 );
  border-radius: 10px;
  
  }
  .content{
      margin-top:1em;
      margin-left:6em;
  }
  .physics h4{
      color:red;
      text-align: center;
  }
  .physics p{
      text-align:center;
      color:#042c54;
      padding:10px;
  }
  .content button{
      width:200px;
      height:40px;
      outline:none;
      border-style:none;
      cursor: pointer;
      background-color: orange;
  }
  .content button:hover{
      background-color: teal;
  }
  @media(max-width:923px){
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 450px;
        height: 320px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}
@media(max-width:680px){
    .re-use h1 {
        color: orange;
        font-size:35px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 450px;
        height: 350px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}

@media(max-width:500px){
    .re-use h1 {
        color: orange;
        font-size:35px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 400px;
        height: 370px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}

@media(max-width:430px){
    .re-use h1 {
        color: orange;
        font-size:35px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 350px;
        height: 370px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}

@media(max-width:380px){
    .re-use h1 {
        color: orange;
        font-size:35px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 320px;
        height: 390px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}


@media(max-width:345px){
    .re-use h1 {
        color: orange;
        font-size:30px;
    }
    .all_subjects__div {
       flex-direction: column;
    
    }
    .physics {
        width: 300px;
        height: 390px;
        border-radius: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
         transition: 0.2s ease-in-out;
    }
}
.roundedCard{
    padding:1rem;
    background-color:#042c54;
}
.display{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
}
.display img{
    width:600px;
}
.list_style{
    
    padding:5px;
}
.list-content h4{
    color:orange;
    font-size:30px;
}
.list_style h3{
    list-style-type: none;
    font-size:17px;
    color:orange;
}
@media(max-width:993px){
    .display img{
        width:500px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:18px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:25px;
        text-align: center;
    }
}
@media(max-width:922px){
    .display img{
        width:500px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:17px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:25px;
        text-align: center;
    }
}
@media(max-width:903px){
    .display{
        flex-direction: column;
    }
    .display img{
        width:500px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:20px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:25px;
        text-align: center;
    }
}
@media(max-width:550px){
    .display{
        flex-direction: column;
    }
    .display img{
        width:400px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:20px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:25px;
        text-align: center;
    }
}
@media(max-width:463px){
    .display{
        flex-direction: column;
    }
    .display img{
        width:400px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:17px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:25px;
        text-align: center;
    }
}
@media(max-width:412px){
    .display{
        flex-direction: column;
    }
    .display img{
        width:300px;
    }
    .list_style h3{
        list-style-type: none;
        font-size:16px;
        color:orange;
        width:100%;
    }

    .list-content h4{
        color:orange;
        font-size:22px;
        text-align: center;
    }
}

.overall-side{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    background-color: orange;
}
.overall-side img{
    width:600px;
}
.content a{
    width:130px;
    height:30px;
    background-color: red;
    outline: none;
    margin-top: 10px;
    border-style: none;
    padding:5px;
    border-style: none;
    border:2px solid white;
}
.overall-side p{
    width:100%;
   
}

.side a{
    text-decoration: none;
    cursor: pointer;
}
.side span{
    
    color:blue;
}
.side h4{
    color:orangered;
}
.side h1{
    color:orange;
}
@media(max-width:1029px){
    .overall-side{
        flex-direction: column;
        flex-wrap: wrap;
        padding:0.5rem;
    }
    .overall-side p{
        width:80%;
     
    }
    .overall-side img{
        width:650px;
    }
    .content{
        margin-right:3rem;
    }
}
@media(max-width:670px){
    .overall-side{
        flex-direction: column;
    }
   
    .overall-side img{
        width:650px;
    }
    .content{
        margin-right:100px;
    }
    .content a{
        margin:15px;
    }
    .content p{
        width:100%;
       
        
    }
}

@media(max-width:650px){
    .overall-side{
        flex-direction: column;
    }
   
    .overall-side img{
        width:600px;
    }
    .content{
        margin-right:150px;
    }
    .content a{
        margin:15px;
    }
    .content p{
        width:100%;
       
        
    }
}

@media(max-width:570px){
    .overall-side{
        flex-direction: column;
    }
   
    .overall-side img{
        width:550px;
    }
    .content{
        margin-right:150px;
    }
    .content a{
        margin:15px;
    }
    .content p{
        width:120%;
       
        
    }
    .content h1{
        font-size:30px;
    }
}


@media(max-width:530px){
    .overall-side{
        flex-direction: column;
    }
   
    .overall-side img{
        width:500px;
    }
    .content{
        margin-right:190px;
    }
    .content a{
        margin:15px;
    }
    .content p{
        width:160%;
       
        
    }
    .content h1{
        font-size:22px;
    }
}


@media(max-width:460px){
    .overall-side{
        flex-direction: column;
        margin-right:270px;
        width:100%;
    }
   
    .overall-side img{
        width:450px;
    }
  
    .content a{
        margin:15px;
    }
    .content p{
        width:160%;
       
        
    }
    .content h1{
        font-size:22px;
    }
}
@media(max-width:400px){
    .overall-side{
        flex-direction: column;
        
       
    }
   .content{
    margin-left:2.5rem;
   }
  
    .overall-side img{
        width:380px;
     
    }
  
  
    .content p{
        width:160%;
       
        
    }
    .content h1{
        font-size:22px;
    }
}

@media(max-width:350px){
    .overall-side{
        flex-direction: column;
        
       
    }
   .content{
    margin-left:1.5rem;
   }
  
    .overall-side img{
        width:350px;
     
    }
  
  
    .content p{
        width:160%;
       
        
    }
    .content h1{
        font-size:22px;
    }
}

@media(max-width:330px){
    .overall-side{
        flex-direction: column;
        
       
    }
   .content{
    margin-left:1rem;
   }
  
    .overall-side img{
        width:370px;
       padding-right:80px;
    }
  
  
    .content p{
        width:160%;
       
        
    }
    .content h1{
        font-size:22px;
    }
}
.competent_staff{
    padding:1rem;
}
.competent_staff h1{
    color:orange;
    font-size:39px;
}
.all_competency{
    display:flex;
    justify-content: center;
    align-items: center;
}
.all_competency img{
    width:400px;
    margin:5px;
   
}

.all_competency a{
    color:blue;
    cursor: pointer;
}
@media(max-width:1029px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:700px;
     
       
    }
}
@media(max-width:740px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:600px;
        margin-left:20px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:660px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:500px;
        margin-left:30px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:570px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:500px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:545px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:450px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:496px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:400px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:450px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:380px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:430px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:360px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:405px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:340px;
        margin-left:10px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:386px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:320px;
        margin-left:5px;
     
       
    }
    .all_competency p{
        width:80%;
        text-align: center;
    }
}
@media(max-width:360px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:300px;
        margin-left:5px;
     
       
    }
    .all_competency p{
        width:90%;
        text-align: center;
    }
}
@media(max-width:340px){
    .all_competency{
     flex-direction: column;
     flex-wrap: wrap;
    }
    .all_competency img{
        width:280px;
        margin-left:3px;
     
       
    }
    .all_competency p{
        width:90%;
        text-align: center;
    }
}
.position-center img{
  width:60px;
}
.position-center a{
    text-decoration: none;
    cursor: pointer;
}
.whatsApp{
    position: absolute;
    top:90%;
    left:80%
}
@media(max-width:980px){
  .whatsApp{
    position: absolute;
    top:80%;
    left:80%
}
}
@media(max-width:860px){
  .whatsApp{
    position: absolute;
    top:70%;
    left:80%
}
}
@media(max-width:840px){
  .whatsApp{
    position: absolute;
    top:60%;
    left:80%
}
}

.form-all{
    margin:2rem;
}
 form{
     position: absolute;
     top:15%;
     left:30%;
     background-color: white;
    margin-left:7rem;
    
     padding:1.5rem;
     box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );
   opacity:0.8;
  align-items:center;
    
}

label{
     padding:10px;
    color:orange;
    font-size:15px;
}
input{
      width:100%;
     padding:5px;
}
.enroll-all button{
    width:360px;
    height:40px;
    background-color: red;
    color:white;
    outline:none;
    border-style: none;
    
    cursor: pointer;
    border-radius: 6px;
}
.enroll-all button:hover{
    background-color: orange;
}


@media(max-width:872px){
    form{
        position: absolute;
        top:12%;
        left:25%;
        background-color: white;
       margin-left:2rem;
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100%;
        padding:5px;
   }
   .enroll-all button{
       width:360px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}

@media(max-width:700px){
    form{
        position: absolute;
        top:12%;
        left:10%;
        background-color: white;
       margin-left:2rem;
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100%;
        padding:5px;
   }
   .enroll-all button{
       width:360px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}


@media(max-width:600px){
    form{
        position: absolute;
        top:12%;
        left:5%;
        background-color: white;
       margin-left:4rem;
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100%;
        padding:5px;
   }
   .enroll-all button{
       width:360px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}


@media(max-width:540px){
    form{
        position: absolute;
        top:12%;
        left:-2%;
        background-color: white;
       margin-left:4rem;
      
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100%;
        padding:5px;
   }
   .enroll-all button{
       width:360px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}


@media(max-width:509px){
    .form-all{
        width:350px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-2%;
        background-color: white;
       margin-left:4rem;
      
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100px;
        padding:5px;
   }
   .enroll-all button{
       width:300px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}


@media(max-width:440px){
    .form-all{
        width:350px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-6%;
        background-color: white;
       margin-left:4rem;
      
        padding:2rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100px;
        padding:5px;
   }
   .enroll-all button{
       width:300px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}

@media(max-width:419px){
    .form-all{
        width:350px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-1%;
        background-color: white;
       margin-left:1.5rem;
      
        padding:1rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100px;
        padding:5px;
   }
   .enroll-all button{
       width:320px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}

@media(max-width:400px){
    .form-all{
        width:350px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-6%;
        background-color: white;
       margin-left:1.5rem;
      
        padding:1rem;
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100px;
        padding:5px;
   }
   .enroll-all button{
       width:320px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}
@media(max-width:388px){
    .form-all{
        width:340px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-5%;
        background-color: white;
       margin-left:1.1rem;
      
      
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:100px;
        padding:5px;
   }
   .enroll-all button{
       width:315px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}

@media(max-width:370px){
    .form-all{
        width:320px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-5%;
        background-color: white;
       margin-left:1.1rem;
      
      
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:80px;
        padding:5px;
   }
   .enroll-all button{
       width:290px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}
@media(max-width:350px){
    .form-all{
        width:300px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-5%;
        background-color: white;
       margin-left:1.1rem;
      
      
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:60px;
        padding:5px;
   }
   .enroll-all button{
       width:270px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}
@media(max-width:332px){
    .form-all{
        width:280px;
        height:630px;
    }
    .form-all h1{
        font-size:24px;
    }
    form{
        position: absolute;
        top:15%;
        left:-5%;
        background-color: white;
       margin-left:1.1rem;
      
      
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
         backdrop-filter: blur( 4px );
         -webkit-backdrop-filter: blur( 4px );
      opacity:0.8;
     align-items:center;
       
   }
   
   label{
        padding:10px;
       color:#111;
       font-size:15px;
   }
   input{
         width:50px;
        padding:5px;
   }
   .enroll-all button{
       width:250px;
       height:40px;
       background-color: red;
       color:white;
       outline:none;
       border-style: none;
       
       cursor: pointer;
       border-radius: 6px;
   }
}
.wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
  }
  
  .loader {
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 999;
  }
.back{
    background-image: url([object Module]);
    background-repeat: no-repeat;
    background-size: cover;
 width:100%;
 height:100vh;
    background-position: center;
    background-attachment: fixed;
}

.ai{
    background-color: teal;
    color:white;
  
}
.ai-big{
    display:flex;
    justify-content: center;
    align-items: center;
}
.ai-big img{
    width:500px;
}
.ai-big button{
    width:230px;
    height:45px;
    outline:none;
    border-style: none;
    background-color: red;
    border-radius: 12px;
    color:white;
  cursor: pointer;
}
.ai p{
    width:75%;
}
@media(max-width:1029px){
    .ai p{
        width:100%;
    } 
}
@media(max-width:820px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:500px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:250px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}

@media(max-width:675px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:500px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:200px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}

@media(max-width:600px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:500px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:150px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}

@media(max-width:550px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:400px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:100px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}
@media(max-width:530px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:400px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:60px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}

@media(max-width:380px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:350px;
    }
    .ai-big button{
        width:230px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:50px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}

@media(max-width:360px){
    .ai-big{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
    }
    .ai-big img{
        width:350px;
    }
    .ai-big button{
        width:200px;
        height:45px;
        outline:none;
        border-style: none;
        background-color: red;
        border-radius: 12px;
        color:white;
        margin-left:45px;
    }
    .ai p{
        width:100%;
    }
    .aside-detail h1{
        text-align: center;
    }
    .aside-detail h5{
        text-align: center;
    }
}
.robotics-mechanics-ai{
    padding:2rem;
    background-color: white;
}
.video-plus-tex{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.video-plus-tex video{
    width:600px;
    
}
.video-plus-tex h1{
    color:orange;
    font-size:23px;
    text-align: center;

}
.data-plus-content{
    margin-left:30px;
}
.data-plus-content p{
    padding:7px;
   
}
.data-plus-content button{
    width:230px;
    height:40px;
    cursor: pointer;
    background-color: red;
    color:white;
    padding:10px;
    font-size:20px;
    border-style: none;
    outline:none;
    border-radius: 8px;
    margin-left:120px;
}
@media(max-width:954px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:600px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}
@media(max-width:645px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:500px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .ai-header{
        font-size:22px;
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}
@media(max-width:525px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:400px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .ai-header{
        font-size:22px;
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}
@media(max-width:430px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:370px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .ai-header{
        font-size:22px;
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}
@media(max-width:398px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:340px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .ai-header{
        font-size:22px;
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}
@media(max-width:360px){
    .video-plus-tex{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .video-plus-tex video{
        width:298px;
        
    }
    .video-plus-tex h1{
        color:orange;
        font-size:20px;
        text-align: center;
    
    }
    .ai-header{
        font-size:22px;
    }
    .data-plus-content{
        margin-left:30px;
    }
    .data-plus-content p{
        padding:7px;
        width:100%;
       
    }
    .data-plus-content button{
        width:230px;
        height:40px;
        cursor: pointer;
        background-color: red;
        color:white;
        padding:10px;
        font-size:20px;
        border-style: none;
        outline:none;
        border-radius: 8px;
        margin-left:120px;
    }
}
.form__content__register h1{
    text-align: center;

    color:orange;
}

.form__content__register{
   
    padding:0.5em;
    
}
.register__submit{
     margin:4em;
     width:500px;
     height:450px;
}
.form__content__register span{
    text-align:center;
    color:red;
    
}

input{
    width:100%;
    padding:10px;
}
label{
    padding:10px;
}

.register__submit button{
    background-color:red;
    width:500px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
.register__submit button:hover{
    background-color:orangered;
}
.register__submit a{
    text-decoration: none;
    color:orange;
}
.register__submit span{
    color:#111;
}
@media(max-width:1029px){
    .register__submit{
    
        margin:4em;
       width:500px;
       height:450px;
       margin-left:4rem;
   }
   .form-register{
    position: absolute;
    top:20%;
    left:30%;
    margin-left:-2rem;
   }
}
@media(max-width:810px){
    .register__submit{
      
        margin:4em;
       width:500px;
       height:450px;
       margin-left:4rem;
       
   }
   .form-register{
  
 
    margin-left:-8rem;
   }
}
@media(max-width:645px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:450px;
       height:550px;
    margin-left:-6rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:390px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
}
@media(max-width:550px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:450px;
       height:550px;
    margin-left:-8rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:390px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
}
@media(max-width:480px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:400px;
       height:500px;
    margin-left:-7rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:340px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
}
@media(max-width:430px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:380px;
       height:520px;
    margin-left:-7rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:330px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
}
@media(max-width:400px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:350px;
       height:520px;
    margin-left:-6rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:320px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
 input{
    width:100px;
}
}

@media(max-width:380px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:300px;
       height:530px;
    margin-left:-4rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:260px !important;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}

}
@media(max-width:360px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:300px;
       height:530px;
    margin-left:-5rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:260px !important;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}

}
@media(max-width:340px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:300px;
       height:530px;
    margin-left:-5rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:260px !important;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}

}
@media(max-width:332px){
    .register__submit{
      
        margin:4em;
    
       margin-left:4rem;
       
   }
   .form-register{
  
    width:280px;
       height:530px;
    margin-left:-5rem;
   }
   .form-register h1{
    font-size:24px;
   }
   .register__submit button{
    background-color:red;
    width:220px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}

}
@media(max-width:1029px){
  .Login-form{
    margin-left:2rem;
  }
}
@media(max-width:993px){
    .Login-form{
      margin-top:2rem;
      margin-left:-3rem;
    }
  }
  @media(max-width:800px){
    .Login-form{
      margin-top:2rem;
      margin-left:-5rem;
    }
  }
  @media(max-width:700px){
    .Login-form{
      margin-top:2rem;
      margin-left:-1rem;
    }
  }
  @media(max-width:645px){
    .register__submit{
      margin-top:2rem;
        margin-left:-7rem;
    }
    .Login-form{
      margin-top:2rem;
        margin-left:-0.2rem;
      }
  }
  @media(max-width:540px){
    
    .Login-form{
      margin-top:2rem;
        margin-left:3rem;
      }
  }
  @media(max-width:510px){
    
    .Login-form{
      margin-top:2rem;
        margin-left:1.5rem;
      }
  }
  @media(max-width:489px){
    
    .Login-form{
      margin-top:2rem;
        width:370px;
        height:360px;
        margin-left:4rem;
      }
      .register__submit button{
        width:300px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:450px){
    
    .Login-form{
      margin-top:2rem;
        width:370px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:310px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:420px){
    
    .Login-form{
      margin-top:2rem;
        width:350px;
        height:360px;
        margin-left:3rem;
      }
      .register__submit button{
        width:280px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:412px){
    
    .Login-form{
      margin-top:2rem;
        width:350px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:320px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:395px){
    
    .Login-form{
      margin-top:2rem;
        width:350px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:320px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:380px){
    .Login-form{
      margin-top:2rem;
        width:350px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:320px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:375px){
    
    .Login-form{
      margin-top:2rem;
        width:330px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:300px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:358px){
    
    .Login-form{
      margin-top:2rem;
        width:300px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:280px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:333px){
    
    .Login-form{
      margin-top:2rem;
        width:298px;
        height:360px;
        margin-left:1.5rem;
      }
      .register__submit button{
        width:278px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:330px){
    
    .Login-form{
      margin-top:2rem;
        width:298px;
        height:360px;
        margin-left:1.5rem;
      }
      .register__submit button{
        width:278px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:325px){
    
    .Login-form{
      margin-top:2rem;
        width:298px;
        height:360px;
        margin-left:1.2rem;
      }
      .register__submit button{
        width:278px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
.pay__all{
    margin-left:2rem;
}
.pay_content{
    display:flex;
    justify-content: space-around;
    align-items: center;
    padding:2em;
}
.pay__all h1{
    text-align: center;
    font-size:30px;
    text-transform: capitalize;
    color:orange;
}

.pay_content p{
    width:100%;
   
}
@media(max-width:996px){
    .terms{
        margin:12px;
    }
}
 @media(max-width:730px){
    .pay__all{
        margin-left:2rem;
    }
    .pay_content{
       flex-direction: column;
        padding:1em;
    }
    .pay__all h1{
        text-align: center;
        font-size:20px;
        text-transform: capitalize;
        color:orange;
    }
    
    .pay_content p{
        width:100%;
       
    }
 }
@media(max-width:430px){
    .pay__all h1{
        text-align: center;
        font-size:20px;
        text-transform: capitalize;
        color:orange;
    } 
}
.payCover{
    display: flex;
    justify-content: center;
    align-items:center;
    width:100%;
}
.payCover img{
    width:100%;
  
}
.payment_cards{
    padding:2rem;
   
}
.payment_cards_three{
    display:flex;
    justify-content: center;
    align-items: center;
    grid-gap:5px;
    gap:5px;
}
.payment_cards_three img{
    width:180px;
    height:180px;
    border-radius:50%;
}
.igsce{
    width:400px;
    height:500px;
    background-color: white;
    padding:10px;
    text-align: center;
    border-radius: 10px;
    -webkit-filter: drop-shadow(.75em .75em .75em);
            filter: drop-shadow(.75em .75em .75em);
}
.bgsce{
    width:400px;
    height:600px;
     background-color: white;
     text-align: center;
     border-radius: 10px;
     -webkit-filter: drop-shadow(.75em .75em .75em);
             filter: drop-shadow(.75em .75em .75em);
  
}
.cambridge{
    width:400px;
    height:500px;
    background-color:white;
    padding:10px;
    text-align: center;
    border-radius: 10px;
    -webkit-filter: drop-shadow(.75em .75em .75em);
            filter: drop-shadow(.75em .75em .75em);
}
.payment_cards_three p{
    padding:5px;
    color:black;
}
.payment_cards_three h2{
    
    color:orange;
}
@media(max-width:1029px){
    .payment_cards_three{
    
      flex-wrap: wrap;
    }
    .payment_cards_three img{
        width:100px;
        height:100px;
        border-radius:50%;
    }
    .igsce{
        width:400px;
        height:500px;
        background-color: white;
        padding:10px;
        text-align: center;
        border-radius: 10px;
        -webkit-filter: drop-shadow(.75em .75em .75em);
                filter: drop-shadow(.75em .75em .75em);
    }
    .bgsce{
        width:400px;
        height:600px;
         background-color: white;
         text-align: center;
         border-radius: 10px;
         -webkit-filter: drop-shadow(.75em .75em .75em);
                 filter: drop-shadow(.75em .75em .75em);
      
    }
    .cambridge{
        width:400px;
        height:500px;
        background-color:white;
        padding:10px;
        text-align: center;
        border-radius: 10px;
        -webkit-filter: drop-shadow(.75em .75em .75em);
                filter: drop-shadow(.75em .75em .75em);
    }
    .payment_cards_three p{
        padding:5px;
        color:black;
    }
    .payment_cards_three h2{
        
        color:orange;
    } 
}
@media(max-width:469px){
   
    .payment_cards_three{
        flex-direction: column;
        flex-wrap: wrap;
      }
      .payment_cards_three img{
          width:100px;
          height:100px;
          border-radius:50%;
      }
      .igsce{
          width:350px;
          height:470px;
          background-color: white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          -webkit-filter: drop-shadow(.75em .75em .75em);
                  filter: drop-shadow(.75em .75em .75em);
      }
      .bgsce{
          width:350px;
          height:600px;
           background-color: white;
           text-align: center;
           border-radius: 10px;
           -webkit-filter: drop-shadow(.75em .75em .75em);
                   filter: drop-shadow(.75em .75em .75em);
        
      }
      .cambridge{
          width:350px;
          height:430px;
          background-color:white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          -webkit-filter: drop-shadow(.75em .75em .75em);
                  filter: drop-shadow(.75em .75em .75em);
      }
      .payment_cards_three p{
          padding:5px;
          color:black;
      }
      .payment_cards_three h2{
          
          color:orange;
      } 
}
@media(max-width:415px){
   
    .payment_cards_three{
        flex-direction: column;
        flex-wrap: wrap;
      }
      .payment_cards_three img{
          width:100px;
          height:100px;
          border-radius:50%;
      }
      .igsce{
          width:300px;
          height:440px;
          background-color: white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          -webkit-filter: drop-shadow(.75em .75em .75em);
                  filter: drop-shadow(.75em .75em .75em);
      }
      .bgsce{
          width:300px;
          height:500px;
           background-color: white;
           text-align: center;
           border-radius: 10px;
           -webkit-filter: drop-shadow(.75em .75em .75em);
                   filter: drop-shadow(.75em .75em .75em);
        
      }
      .cambridge{
          width:300px;
          height:440px;
          background-color:white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          -webkit-filter: drop-shadow(.75em .75em .75em);
                  filter: drop-shadow(.75em .75em .75em);
      }
      .payment_cards_three p{
          padding:5px;
          color:black;
      }
      .payment_cards_three h2{
          font-size:24px;
          color:orange;
      } 
}

@media(max-width:356px){
   
    .payment_cards_three{
        flex-direction: column;
        flex-wrap: wrap;
      }
      .payment_cards_three img{
          width:100px;
          height:100px;
          border-radius:50%;
      }
      .igsce{
          width:280px;
          height:460px;
          background-color: white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          -webkit-filter: drop-shadow(.75em .75em .75em);
                  filter: drop-shadow(.75em .75em .75em);
      }
      .bgsce{
          width:280px;
          height:500px;
           background-color: white;
           text-align: center;
           border-radius: 10px;
           -webkit-filter: drop-shadow(.75em .75em .75em);
                   filter: drop-shadow(.75em .75em .75em);
        
      }
      .cambridge{
          width:280px;
          height:460px;
          background-color:white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          -webkit-filter: drop-shadow(.75em .75em .75em);
                  filter: drop-shadow(.75em .75em .75em);
      }
      .payment_cards_three p{
          padding:5px;
          color:black;
      }
      .payment_cards_three h2{
          font-size:24px;
          color:orange;
      } 
}

@media(max-width:336px){
    .payment_cards{
        margin-left:-15px;
       
    }
    .payment_cards_three{
        flex-direction: column;
        flex-wrap: wrap;
      }
      .payment_cards_three img{
          width:100px;
          height:100px;
          border-radius:50%;
      }
      .igsce{
          width:280px;
          height:460px;
          background-color: white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          -webkit-filter: drop-shadow(.75em .75em .75em);
                  filter: drop-shadow(.75em .75em .75em);
      }
      .bgsce{
          width:280px;
          height:500px;
           background-color: white;
           text-align: center;
           border-radius: 10px;
           -webkit-filter: drop-shadow(.75em .75em .75em);
                   filter: drop-shadow(.75em .75em .75em);
        
      }
      .cambridge{
          width:280px;
          height:460px;
          background-color:white;
          padding:10px;
          text-align: center;
          border-radius: 10px;
          -webkit-filter: drop-shadow(.75em .75em .75em);
                  filter: drop-shadow(.75em .75em .75em);
      }
      .payment_cards_three p{
          padding:5px;
          color:black;
      }
      .payment_cards_three h2{
          font-size:24px;
          color:orange;
      } 
}
.grid-wrapper{
    background-color: white;
    padding:10px;
}

.grid-wrapper img{
max-width: 100%;
height:auto;
vertical-align: middle;
display: inline-block;
}
.grid-wrapper>div{
    display:flex;
    justify-content: center;
    align-items: center;
}
.grid-wrapper>div>img{
    width:100%;
    height:100%;
    object-fit: cover;
    border-radius: 5px;
}
.grid-wrapper{
    display:grid;
    grid-gap:10px;
    grid-template-columns:repeat(auto-fit,minmax(250px,1fr))
     ;
     grid-auto-rows: 200px;
     grid-auto-flow: dense;
}
.grid-wrapper.wide{
    grid-column: span 2;
}
.grid-wrapper.tall{
    grid-row: span2;
}
.grid-wrapper.big{
    grid-column: span 2;
    grid-row: span 2;
}
.main{
    margin:0;
    padding:0;
    background-color: orange;
    display:flex;
    justify-content: center;
    align-items: center;
    min-height:100vh;
}

.clock h1{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size:4em;
    font-weight:500;
    color:#16CC86;
}
.clock,.screen{
    display:flex;
    justify-content: center;
    align-items: center;
}
.clock{
    width:25em;
    height:25em;
    background-color: #111;
    border-radius: 20px;
}
.screen{
    width:25em;
    height:10em;
    border:4px solid #000;
    border-radius: 15px;
    background: linear-gradient(
        -45deg,#1f272b 0%,
        #1f272b 50%,
        #1b1f21 50%,
        #1b1f21 100%
    );
}
.calendar-plus-table{
   
    background-color: orangered;
    color:white;
}
.time-table{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px;
}
.time-table h3{
    color:white;
}
.time-table a{
    text-decoration: none;
    color:white;
}
.time-table input{
    padding:10px;
    width:200px;
    margin:5px;
    background-color:white;
    cursor: pointer;
}
.list-of-forms {
    font-size:20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.list-of-forms {
    font-size:20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.sub-list-styles p a{
    display:none;

}
.sub-list-styles a{
    text-decoration: none;
    color:white;
    font-size:20px;
}
.list-of-forms :hover .sub-list-styles {
    display:block;
    background-color: red;
    
}
.classes-and-days{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.space{
    margin-right: 20px;
    
}
.drop{
    display:none;
}
.space :hover .drop{
 display: block;
 cursor: pointer;
}
.classes-and-days button{
    width:200px;
    height:40px;
    background-color: white;
    color:red;
    cursor: pointer;
    border: none;
    outline:none;
    padding:10px;
}
@media(max-width:766px){
    .time-table{
        flex-direction: column;
    }
}
.link_to_days{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.link_to_days a{
    text-decoration: none;
    color:red;
}
.link_to_days p{
    font-size:18px;
}
.generic-message p{
    margin:80px;
    width:80%;
    font-size:32px;
    text-align: center;
}
@media(max-width:624px){
    .generic-message p{
        font-size: 22px;
        width:70%;
    }
}
@media(max-width:387px){
    .generic-message p{
        font-size: 22px;
        width:80%;
        margin:30px;
    }
}


.the_actual_video{
    width:100%;
    
}
.the_actual_video video{
   width:100%;
  
}
.video_lectures_for_free{
   
    width:100%;
    padding:1rem;
}

.real_video_lessons{
    display:flex;
    justify-content: space-between;
    align-items: center;
    
}
.video_lectures_for_free h1{
    text-align: left;
    color:red;
    font-size:45px;
}
.real_video_lessons video{
    width:400px;
    margin-left:10px;
}
.real_video_lessons p{
    color:black;
}


.VideoInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
  }
  
  .VideoInput_input {
    display: block
  }
  
  .VideoInput_video {
    display: block;
    margin: 0;
  }
  
  .VideoInput_footer {
    background: #eee;
    width: 100%;
    min-height: 40px;
    line-height: 40px;
    text-align: center;
  }

.sub-nav-bar{
    display:flex;
    justify-content: space-between;
    background-color: teal;
    align-items: center;
}
.sub-nav-bar h1{
    color:orange;
}
.the-links-to-logins-plus-register{
    display:flex;
    justify-content: space-between;
    align-items: center;
    
}
.the-links-to-logins-plus-register p{
    color:black;
}
.the-links-to-logins-plus-register a{
    text-decoration: none;
    color:orange;
    margin-right:10px;
}
.sub-nav-bar button{
    color:#fff;
    width:200px;
    height:40px;
    background-color:red;
    border-radius: 6px;
    outline: none;
    border-style:none;
    font-family:var(--font-family);
    font-size: 22px;
    font-weight: 600;
    margin: 1rem;
}
.sub-nav-bar button:hover{
    background-color: orange;
}

.form-container{
   

    width:350px;
    height:400px;
    background-color: #ffff;
}
.form-general h1{
    text-align: center;
    font-size: 35px;
    color:orange;
 
    padding:15px;
  
}
input{
    width:100%;
    padding:10px;
}
label{
    padding:10px;
}
.form-container button{
    background-color:red;
    width:370px;
    color:white;
    padding:10px;
    margin-top:10px;
    font-size:16px;
    height:40px;
    border-style: none;
    outline:none;
  border-radius: 5px;
    text-align: center;
}
.form-container button:hover{
    background-color:orangered;
}

.side-bar-rounded-all{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.inbox-message{
    color:orange;
}
.inbox-message a{
    text-decoration: none;
}
.school-reports a{
    text-decoration: none;
}
.contact-form-with-both-sides {
   

    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;


}

.contact-form-with-both-sides h3 {
    text-align: center;
    width:100%;
    padding: 10px;
    ;
    font-size: 22px;
}

.contact-form-with-both-sides h2 {
    color: white;
    text-align: center;
}

.sub-contact-form-div {

    background-color: white;
    border: 10px solid orange;
    -webkit-filter: drop-shadow(.75em .75em .75em);
            filter: drop-shadow(.75em .75em .75em);
    border-radius: 15px;
    padding: 20px;
}



.the-detail-side-div p {
    width: 100%;
    padding: 5px;
    text-align: center;
    color: white;
}

.sub-contact-the-form-side {
  width:300px;
  height:400px;
  margin-right:400px;
    padding: 10px;
    background-color: white;
    color: #111;
}

label {
    width: 100px;
    padding: 20px;
    color: orange;
}

input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin: 5px;
    color: orange;
}
textarea{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
    color: orange;
}

.sub-contact-the-form-side button {
    background-color: red;
    width: 250px;
    height: 50px;
    outline: none;
    border-style: none;
    cursor: pointer;
    border-radius: 8px;
    color: white;
    font-size: 20px;
    margin-left: 120px;
}


.sub-contact-the-form-side h3 {
    color: orange;
}
.sidebar-item {
    padding: 0.75em 1em;
    display: block;
    /* border-radius: 5px;
    // border-bottom: 2px solid #ccc;
    */
    background-color: rgba(255, 255, 255, 0.1);
    transition: background-color 0.15s;
  }
  
  .s-parent {
    border-bottom: 2px solid #ccc;
  }
  
  .sidebar-item:hover {
    background-color: #eee;
  }
  
  .sidebar-title {
    display: flex;
    font-size: 1.2em;
    justify-content: space-between;
    align-items: center;
  }
  
  .sidebar-title span {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 12px;
    gap: 12px;
    transition: all 0.5s;
    font-size: 1.6rem;
  }
  
  .sidebar-title span .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    font-size: 25px;
  }
  
  .sidebar-title .arrow-icon {
    cursor: pointer;
    transition: all 0.5s;
  }
  
  .sidebar-item.open > .sidebar-title .arrow-icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  
  .sidebar-content {
    padding-top: 0.25em;
    height: 0;
    overflow: hidden;
  }
  .sidebar-item.open > .sidebar-content {
    height: auto;
  }
  
  .s-child {
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-top: 5px;
  }
  
  .s-child:hover {
    border: 1px solid blue;
  }
  

  
  .active {
    color: red;
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    background-color: #eee;
  }
  
  .active::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    right: 0;
    top: 0;
    background-color: green;
  }
  
  .sublink .sidebar-item .sidebar-title {
    padding-left: 40px;
  }

.layout{
    display:flex;
}
main {
    width: 100%;
 padding: 20px;
color: #ccc;
}
.side-bar{
    position: absolute;
    top: 19%;
    left: 0;
    width: 230px;
    height: 630px;
    flex-shrink: 0;
    background-color: #111;
    overflow: auto;
    color: white;
    transition: all 0.5s; 
    overflow: hidden;
}
.logo-top img{
    width:50px;
    height:50px;
    border-radius: 50%;
}
.top-section{
    display: flex;
    align-items: center;
    padding: 10px 15px;
    transition: all 0.5s;
  background-color: blue;
  height: 70px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 70;
    cursor: pointer;
    color: white;
    transition: all 0.3s;
}

.footer-main p{
    text-align: center;
}
.overall-content{
    padding:0.5rem;
}
.flex-container{
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.name{
    color: orangered;
}
.overall-content button{
    color:white;
    padding:5px;
    background-color: orange;
}
.\--pad {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  

.library-container {
    width:100%;
    height:100%;
}

.library-container img{

  width:100%

}
/*.lib-overlay{
    width:100%;
    height:140vh;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
   
}
*/
.library-info{
    position: absolute;
    top:60%;
    left:45%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}
.library-info h1{
    text-align: center;
    font-size:40px;
    color:red;
}
.buttons{
    display:flex;
    justify-content: center;
    align-items: center;
}
.buttons input{
    outline:none;
    border: none;
    width:250px;
    padding:10px;
  
    color:#111;
    
}
.buttons button{
    width:120px;
    height:40px;
    outline:none;
    border:none;
    cursor: pointer;
    background-color: red;
    color:white;
    border-radius: 6px;
    padding:4px;
}
.library-cards{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
@media(max-width:1029px){
   .library-cards{
    flex-wrap: wrap;
   }
}
@media(max-width:480px){
    .library-info{
        position: absolute;
        top:45%;
        left:45%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
    }

.library-info h1{
    text-align: center;
    font-size:26px;
    color:red;
    font-weight: bold;
}
.buttons{
   flex-direction: column;
}
.buttons input{
    outline:none;
    border: none;
    width:250px;
    padding:10px;
  
    color:#111;
    
}
.buttons button{
    width:220px;
    height:40px;
    outline:none;
    border:none;
    cursor: pointer;
    background-color: red;
    color:white;
    border-radius: 6px;
    padding:4px;
}
.library-container img{

    width:100%;
    height:60vh;
  
  }
}

.library-details-container{
    margin:30px;
    width:350px;
    height:452px;
    padding:15px 0 15px 0;
    border-radius: 6px;
    background: rgba( 255, 255, 255, 0.25 );
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );

}
.library-details-container:hover{
    transition: 1s ;
  
}
.library-details-container img{
    width:350px;
    height:200px;
}
.library-details-container h4{
    color:red;
    text-align: center;
}
.library-details-container p{
    color:#111;
    width:100%;
    padding:4px;
}
.library-details-container button{
    width:345px;
    height:40px;
    display: inline-block;
    color:white;
    background-color: red;
    cursor: pointer;
    border: none;
    outline:none;
    padding:4px;
    border-radius: 6px;
    margin:3px;
}

@media(max-width:380px){
   
.library-details-container{
    margin:30px;
    width:300px;
    height:452px;
    padding:15px 0 15px 0;
    border-radius: 6px;
    background: rgba( 255, 255, 255, 0.25 );
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );

}
.library-details-container:hover{
    transition: 1s ;
  
}
.library-details-container img{
    width:300px;
    height:200px;
}
.library-details-container h4{
    color:red;
    text-align: center;
}
.library-details-container p{
    color:#111;
    width:100%;
    padding:4px;
}
.library-details-container button{
    width:285px;
    height:40px;
    display: inline-block;
    color:white;
    background-color: red;
    cursor: pointer;
    border: none;
    outline:none;
    padding:4px;
    border-radius: 6px;
    margin:3px;
} 
}



.social__icons{
    background-color: #111;
    color:white;
    padding:10px;
    padding:1rem;
}
.icons{
    display:flex;
    justify-content: space-around;
    align-items:center;
}
.icons a{
    text-decoration: none;
    cursor: pointer;
}
.footer{
    background-color: #111;
}

.footer-inner{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding:2rem;
 
}
.footer-inner h2{
    color:white;
    font-size:25px;
    text-align: center;
}
.footer-inner ul li{
    list-style-type: none;
    color:white;
    text-align: center;
}
.footer-inner img{
    width:100px;
    height:100px;
    border-radius: 50%;
}
@media(max-width:1029px){
    .footer-inner h2{
        color:#FAF9F6;
        font-size:20px;
    }
    .footer-inner ul li{
        list-style-type: none;
        color:white;
        text-align: center;
    }
    .footer-inner img{
        width:100px;
        height:100px;
        border-radius: 50%;
    }
}
@media(max-width:940px){
    .footer-inner{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding:2rem;
        
    }
    .Programs{
        margin-left:6px;
    }
    .footer-inner h2{
        color:#FAF9F6;
        font-size:20px;
    }
    .footer-inner ul li{
        list-style-type: none;
        color:white;
        text-align: center;
    }
    .footer-inner img{
        width:100px;
        height:100px;
        border-radius: 50%;
    }
}
@media(max-width:923px){
    .footer-inner{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding:2rem;
        
    }
    .Programs{
        margin-left:10px;
    }
    .footer-inner h2{
        color:#FAF9F6;
        font-size:20px;
        text-align: center;
    }
    .footer-inner ul li{
        list-style-type: none;
        color:white;
        text-align: center;
    }
    .footer-inner img{
        width:100px;
        height:100px;
        border-radius: 50%;
    }
}
@media(max-width:805px){
    .footer-inner{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        padding:2rem;
        
    }
    .Programs{
        margin-left:10px;
    }
    .footer-inner h2{
        color:#FAF9F6;
        font-size:20px;
        text-align: center;
    }
    .footer-inner ul li{
        list-style-type: none;
        color:white;
        text-align: center;
    }
    .footer-inner img{
        width:100px;
        height:100px;
        border-radius: 50%;
    }
}
