.contact-form-with-both-sides {
   

    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;


}

.contact-form-with-both-sides h3 {
    text-align: center;
    width:100%;
    padding: 10px;
    ;
    font-size: 22px;
}

.contact-form-with-both-sides h2 {
    color: white;
    text-align: center;
}

.sub-contact-form-div {

    background-color: white;
    border: 10px solid orange;
    filter: drop-shadow(.75em .75em .75em);
    border-radius: 15px;
    padding: 20px;
}



.the-detail-side-div p {
    width: 100%;
    padding: 5px;
    text-align: center;
    color: white;
}

.sub-contact-the-form-side {
  width:300px;
  height:400px;
  margin-right:400px;
    padding: 10px;
    background-color: white;
    color: #111;
}

label {
    width: 100px;
    padding: 20px;
    color: orange;
}

input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin: 5px;
    color: orange;
}
textarea{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
    color: orange;
}

.sub-contact-the-form-side button {
    background-color: red;
    width: 250px;
    height: 50px;
    outline: none;
    border-style: none;
    cursor: pointer;
    border-radius: 8px;
    color: white;
    font-size: 20px;
    margin-left: 120px;
}


.sub-contact-the-form-side h3 {
    color: orange;
}