@media(max-width:1029px){
  .Login-form{
    margin-left:2rem;
  }
}
@media(max-width:993px){
    .Login-form{
      margin-top:2rem;
      margin-left:-3rem;
    }
  }
  @media(max-width:800px){
    .Login-form{
      margin-top:2rem;
      margin-left:-5rem;
    }
  }
  @media(max-width:700px){
    .Login-form{
      margin-top:2rem;
      margin-left:-1rem;
    }
  }
  @media(max-width:645px){
    .register__submit{
      margin-top:2rem;
        margin-left:-7rem;
    }
    .Login-form{
      margin-top:2rem;
        margin-left:-0.2rem;
      }
  }
  @media(max-width:540px){
    
    .Login-form{
      margin-top:2rem;
        margin-left:3rem;
      }
  }
  @media(max-width:510px){
    
    .Login-form{
      margin-top:2rem;
        margin-left:1.5rem;
      }
  }
  @media(max-width:489px){
    
    .Login-form{
      margin-top:2rem;
        width:370px;
        height:360px;
        margin-left:4rem;
      }
      .register__submit button{
        width:300px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:450px){
    
    .Login-form{
      margin-top:2rem;
        width:370px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:310px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:420px){
    
    .Login-form{
      margin-top:2rem;
        width:350px;
        height:360px;
        margin-left:3rem;
      }
      .register__submit button{
        width:280px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:412px){
    
    .Login-form{
      margin-top:2rem;
        width:350px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:320px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:395px){
    
    .Login-form{
      margin-top:2rem;
        width:350px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:320px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:380px){
    .Login-form{
      margin-top:2rem;
        width:350px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:320px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:375px){
    
    .Login-form{
      margin-top:2rem;
        width:330px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:300px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:358px){
    
    .Login-form{
      margin-top:2rem;
        width:300px;
        height:360px;
        margin-left:2rem;
      }
      .register__submit button{
        width:280px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:333px){
    
    .Login-form{
      margin-top:2rem;
        width:298px;
        height:360px;
        margin-left:1.5rem;
      }
      .register__submit button{
        width:278px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:330px){
    
    .Login-form{
      margin-top:2rem;
        width:298px;
        height:360px;
        margin-left:1.5rem;
      }
      .register__submit button{
        width:278px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }
  @media(max-width:325px){
    
    .Login-form{
      margin-top:2rem;
        width:298px;
        height:360px;
        margin-left:1.2rem;
      }
      .register__submit button{
        width:278px;
      }
      .register__submit h1{
        font-size:24px;
      }
  }